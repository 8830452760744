import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Table,Box,Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { alpha, styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as SearchIcon } from '../../assets/images/SearchIcon.svg'; 
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";  
import { ReactComponent as Sort } from "../../assets/images/sort.svg";  
import { ReactComponent as ColumnIcon } from "../../assets/images/columnIcon.svg";  
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup'; 
import DeleteIcon from '@mui/icons-material/Delete';
import './AlarmSummary.scss'

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled('input')(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    textIndent:'100px',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc( ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    border:"none",
    [theme.breakpoints.up('sm')]: {
        width: '10px',
        '&:focus': {
            textIndent:'1.2rem',
            width: '20ch',
        },
    },
}));

export default function AlarmSummary() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
 

  function EnhancedTableToolbar(props) {
    // const { rowCount,numSelected } = props;
  
    return (
      <Box
       sx={{p:0,
                    display: 'flex',
                    flex: '1 1 100%' ,
                    alignItems: 'center', 
                    borderColor: 'divider',
                    borderRadius: 2,   
                    
                }}
      >
        <Typography 
          color="inherit"
          variant="subtitle1"
          component="div"
          sx={{fontSize:"12px",color:"#525252"}}
        >
      Last refresh 2024/02/09 13:10:01
        </Typography>
      
      
      </Box>
    );
  }

   
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

  return (
    <Box>
    Alarm summary
       {/* <Toolbar className="toolbar"> 
            <EnhancedTableToolbar/>        
            
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    onChange={handleSearchChange}
                    value={searchQuery}
                    inputProps={{ 'aria-label': 'search' }}
                /> 
            </Search>
            <ButtonGroup className="alarmctions" variant="text" aria-label="Basic button group">
                  <Button startIcon={<FilterIcon />}>Filter</Button>
                  <Button startIcon={<Sort />}>Two</Button>
                  <Button startIcon={<ColumnIcon />}>Three</Button>
            </ButtonGroup> */}
            {/* <CloseIcon /> */}
        {/* </Toolbar> */}
      {/* <TableContainer sx={{ maxHeight: 650,height:"100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer> */}
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  );
}
