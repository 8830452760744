import { useState } from "react";
import axios from "axios";

const usePost = (url) => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = localStorage?.getItem("accessToken");
  const postData = async (url, payload) => {
    setLoading(true);
    setData(false);
    setError(false);
    try {
      const isFormData = payload instanceof FormData;
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      };
      // Check if response status indicates success
      const response = await axios.post(url, payload, { headers });
      if (response.status === 202 || response.status === 200) {
        // Parse the response data
        let result;
        // Parse the response data if it's a string
        if (typeof response.data !== "string") {
          // Use response.data directly if it's already an object
          result = response.data;
        } else {
          result = JSON.parse(response.data);
        }
        // Assuming 'data' field is what you're interested in
        setData(result);
        setError(false);
      } else {
       
        // Handle unexpected status code
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      setError(true);
      // setError(error.message || "Something went wrong");
      setData(false);
    } finally {
      setLoading(false);
      
      
    }
  };
  return { loading, error, data, postData };
};

export default usePost;
