import React from "react";
import {
  Box,
  InputLabel,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { ReactComponent as Checkmark } from '../../../assets/images/Checkmark-filled.svg';

const CustomTextField = ({
  label,
  id,
  name,
  isUrl,
  placeholder,
  error,
  fullWidth,
  handleChange,
  helperText,
  value,
  type,
  width = "",
  startAdornment,
  color = "#F4F4F4",
  maxLength = 128,
  height = "40px",
  disabled = false,
  minLength = "",
}) => {

   // Format the input value to match 000-0000 format
   const formatPostalCode = (inputValue) => {
     // Remove non-digit characters
  const formattedValue = inputValue.replace(/\D/g, "");

  // Format as 000-0000
  let formattedPostalCode = formattedValue.slice(0, 7).replace(/(\d{3})(\d{0,4})/, "$1-$2");

  // Remove any trailing hyphens
  formattedPostalCode = formattedPostalCode.replace(/-+$/, "");

  return formattedPostalCode;
  };
  
    // Handle change to format input as user types
    const handleInputChange = (e) => { 
     if(name==="postalCode") {
      const inputValue =e.target.value;
      const formattedValue = formatPostalCode(inputValue);
      const ev = {name:name,value:formattedValue}
      handleChange(ev); // Pass formatted value to parent component
      }else{
        handleChange(e); 
      }
    };

    
  return (
    <Box sx={{ marginTop: "16px" }}>
      <InputLabel
        htmlFor="input-with-icon-adornment"
        sx={{
          marginBottom: "6px",
        }}
        className="globalInputLabelStyle"
      >
        {label}
      </InputLabel>
      <TextField
        fullWidth={fullWidth}
        id={id}
        name={name}
        error={error}
        helperText={helperText}
        disabled={disabled}
        inputProps={{ maxLength: maxLength, minLength: minLength }}
        sx={{
          width: width,
          "& input::placeholder": {
            fontSize: "14px",
          },
          "& .MuiInputBase-root": {
            height: height,
            backgroundColor: color ? color : "transparent",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: error ? "#DA1E28" : "#8D8D8D",
              borderWidth: error ? "2px" : "1px",
            },
            "&:hover fieldset": {
              borderColor: error ? "#DA1E28" : "#8D8D8D",
              borderWidth: error ? "2px" : "1px",
            },
            "&.Mui-focused fieldset": {
              borderColor: error ? "#DA1E28" : "#0F62FE",
              borderWidth: error ? "2px" : "1px",
            },
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px white inset", // Override autofill background
            WebkitTextFillColor: "inherit", // Override autofill text color
          },
        }}
        InputProps={{
          startAdornment: startAdornment && startAdornment,
          endAdornment: error ? (
            <InputAdornment position="end">
              <IconButton aria-label="error">
                <ErrorIcon color="error" />
              </IconButton>
            </InputAdornment>
          ):isUrl? <Checkmark />:'',
        }}
        onInput={(e) => {
          const currentValue = e?.target?.value;
          if (currentValue.length > maxLength) {
            e.target.value = currentValue?.slice(0, maxLength);
          }
        }}
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        type={type}
      />
    </Box>
  );
};

export default CustomTextField;
