/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../App.scss";
import AlarmHome from "./../alarm/AlarmHome";
import AuditHome from "./../audit/AuditHome";
import "../langing-page/LandingPages.scss";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";
import SettingTabs from "../setting/SettingTabs";
import LandingPage from "../langing-page/Index";
import EmptyLanding from "../langing-page/EmptyLanding";
import TopNavigation from "../top-common-navigation/Index";
import useFetch from "../../common/hook/useFetch";
import { prefectures } from "../setting/CustomerData";
import {
  setAllCustomers,
  setMyFavoriteLists,
  setMyRecentSearchList,
  setSelectedCategory,
  setSelectedAreas,
  setSelectedSites, setRecentSearchId, setUpdateBeadcrumbs
} from "../../redux/slices/customerFilterSlice";
import { getSavedFavorite, getRecentSearch, getHomeAllSiteList, customerListURL, recentSearchURL } from "../../services/apiConfig";
import usePost from "../../common/hook/usePost";

const CenterContent = ({ type, user }) => {
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const { loading } = useSelector((state) => state?.loginInfo);
  const dispatch = useDispatch();
  const { allCustomers, isDefaultDeleted, myFavoriteLists, recentSearchId } = useSelector((state) => state?.customer);
  const userId = localStorage.getItem("userId");
const [nocustomer,setNoCustomer]=useState(false)

  // Use a ref to track if the effect has run
  const hasRun = useRef(false);

  const {
    data: fetchCustomers,
    error: fetchCustomersError,
    loading: fetchCustomersLoading,
    fetchData: getCustomersList,
  } = useFetch();

  const {
    data: savedFavorite,
    error: savedFavoriteError,
    loading: savedFavoriteLoading,
    fetchData: getAllSavedFavorite,
  } = useFetch();

  const {
    data: recentSearchData,
    error: recentSearchError,
    loading: recentSearchLoading,
    fetchData: getRecentSearchData,
  } = useFetch();


  const {
    data: fetchsitesData,
    error: fetchsiteError,
    loading: fetchsiteErrorLoading,
    fetchData: getSitesDataList,
  } = useFetch();

  const {
    loading: recenSearchLoading,
    error: recenSearchError,
    data: recenSearchData,
    postData: doPostrecetSearch,
  } = usePost();

useEffect(() => {
  if (type === "home") {
    getCustomersList(customerListURL);
    getAllSavedFavorite(getSavedFavorite(userId));
    getRecentSearchData(getRecentSearch(userId));
    dispatch(setUpdateBeadcrumbs(true));
  }
  
}, []); // Empty dependency array ensures this runs only once on mount

useEffect(() => {
  if (type === "setting") {
    getCustomersList(customerListURL);
    getAllSavedFavorite(getSavedFavorite(userId));
    getRecentSearchData(getRecentSearch(userId));
    dispatch(setUpdateBeadcrumbs(true));
  }
  
}, [type]); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    if (!fetchCustomersLoading && !fetchCustomersError && fetchCustomers) {
      if (fetchCustomers?.length > 0) {
        const sortedCustomer = fetchCustomers?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        dispatch(setAllCustomers(sortedCustomer));
        setNoCustomer(false)
      }else{
        setNoCustomer(true)
      }
    }
  }, [fetchCustomers, fetchCustomersError, fetchCustomersLoading]);

  useEffect(() => {
    if (savedFavorite && !savedFavoriteError && !savedFavoriteLoading) {
      dispatch(setMyFavoriteLists(savedFavorite));
    }
  }, [savedFavorite, savedFavoriteError, savedFavoriteLoading]);

  useEffect(() => {
    if (recentSearchData && !recentSearchError && !recentSearchLoading) {
      dispatch(setMyRecentSearchList(recentSearchData));
    }
  }, [recentSearchData, recentSearchError, recentSearchLoading]);

  useEffect(() => {
    if (savedFavorite && !savedFavoriteError && !savedFavoriteLoading&&savedFavorite?.length>0) {

      // Check for default favorite
      const defaultFavorite = savedFavorite?.find(fav => fav.defaultFavourite);
      if (defaultFavorite) {

        const states = defaultFavorite?.siteList.map(item => prefectures.find(pref => pref.value === item.state)?.label);
        const areas = [...new Set(states)];
        const sites = defaultFavorite?.siteList.map(item => ({
          id: item.siteId,
          state: prefectures.find(pref => pref.value === item.state)?.label,
          name: item.siteName
        }));

        dispatch(setSelectedCategory({
          id: defaultFavorite.customerId,
          name: defaultFavorite.customerName,
          displayName: defaultFavorite.customerName,
          favouriteName: defaultFavorite.favouriteName
        }));
        dispatch(setSelectedAreas(areas));
        dispatch(setSelectedSites(sites));

        // To get the user Search ID
        const siteList = sites?.map(site => ({
          siteId: site.id,
          state: prefectures?.find(pref => pref.label === site.state)?.value,
          siteName: site.name
        }));

        const recentSearchPayload = {
          "userId": userId,
          "customerId": defaultFavorite.customerId,
          "customerName": defaultFavorite.favouriteName,
          "siteList": siteList,
          "lastUsed": new Date().getTime()
        }; 
        doPostrecetSearch(recentSearchURL, recentSearchPayload);

        return; // Exit early if a default favorite was dispatched
      }
    }

    if (((savedFavorite?.length>0 && !savedFavoriteError && !savedFavoriteLoading)||(savedFavorite?.length==0 && !savedFavoriteError && !savedFavoriteLoading))&&(recentSearchData && !recentSearchError && !recentSearchLoading)) {
      // Check for default favorite
      const defaultFavorite = savedFavorite?.find(fav => fav.defaultFavourite);
      if(!defaultFavorite) {  
        const states = recentSearchData[0]?.siteList?.map(item => prefectures.find(pref => pref.value === item.state)?.label);
      const areas = [...new Set(states)];
      const sites = recentSearchData[0]?.siteList.map(item => ({
        id: item.siteId,
        state: prefectures.find(pref => pref.value === item.state)?.label,
        name: item.siteName
      }));

      // Check if recent search data is available
      if (recentSearchData.length > 0) {
        dispatch(setSelectedCategory({
          id: recentSearchData[0].customerId,
          name: recentSearchData[0].customerName,
          displayName: recentSearchData[0].customerName
        }));
        dispatch(setSelectedAreas(areas));
        dispatch(setSelectedSites(sites));

        // To get the user Search ID
        const siteList = sites?.map(site => ({
          siteId: site.id,
          state: prefectures?.find(pref => pref.label === site.state)?.value,
          siteName: site.name
        }));

        const recentSearchPayload = {
          "userId": userId,
          "customerId": recentSearchData[0].customerId,
          "customerName": recentSearchData[0].customerName,
          "siteList": siteList,
          "lastUsed": new Date().getTime()
        }; 
        doPostrecetSearch(recentSearchURL, recentSearchPayload);

        return; // Exit early if a recent search was dispatched
      }
    
    }
    }

    if (
      savedFavorite?.length === 0 &&
      recentSearchData?.length === 0 &&
      fetchCustomers?.length > 0
    ) {
      // Make sure the effect only runs once
      if (!hasRun.current) {
        // Fallback to fetchCustomers
        const customer = fetchCustomers[0];
        if (customer) {
          dispatch(
            setSelectedCategory({
              id: customer.id,
              name: customer.name,
              displayName: customer.displayName,
            })
          );

          getSitesDataList(getHomeAllSiteList(customer.id));

          // Ensure fetchsitesData is defined before processing
          if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
            const states = fetchsitesData
              ?.map((item) =>
                prefectures.find((pref) => pref.value === item.state)?.label
              )
              .filter(Boolean);
            const areas = [...new Set(states)];
            const sites = fetchsitesData?.map(item => ({
              id: item.id,
              state: prefectures.find(pref => pref.value === item.state)?.label,
              name: item.name
            }));

            dispatch(setSelectedAreas(areas));
            dispatch(setSelectedSites(sites));

            // To get the user Search ID
            const siteList = sites?.map(site => ({
              siteId: site.id,
              state: prefectures?.find(pref => pref.label === site.state)?.value,
              siteName: site.name
            }));

            const recentSearchPayload = {
              "userId": userId,
              "customerId": customer.id,
              "customerName": customer.displayName,
              "siteList": siteList,
              "lastUsed": new Date().getTime()
            };
            hasRun.current = true;
            if (sites?.length > 0) { 
              doPostrecetSearch(recentSearchURL, recentSearchPayload);
            }

          }
        }
      }
    }
  }, [ fetchCustomers, savedFavorite, savedFavoriteError, savedFavoriteLoading,recentSearchData, recentSearchError, recentSearchLoading ]);

  useEffect(() => {
    if (!recenSearchLoading && !recenSearchError && recenSearchData) {
      dispatch(setRecentSearchId(recenSearchData?.result?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recenSearchLoading, recenSearchError, recenSearchData])


  useEffect(() => {
    if (type != "setting") {
      setShowAddCustomer(false);
    }
  }, [type]);

  const getUIBasedOnLocation = (typeVal) => {
    switch (typeVal) {
      case "home":
        return (
          <React.Fragment>
            {allCustomers?.length > 0 && <LandingPage />}
            { nocustomer && (
              <EmptyLanding
                user={user}
                showAddCustomer={showAddCustomer}
                setShowAddCustomer={setShowAddCustomer}
                type={type}
              />
            )}
          </React.Fragment>
        );
      case "setting":
        return (
          <SettingTabs
            user={user}
            showAddCustomer={showAddCustomer}
            setShowAddCustomer={setShowAddCustomer}
            type={type}
          />
        );
      case "alarm":
        return <AlarmHome type={type} />;
      case "audit":
        return <AuditHome />;
      default:
        return null;
    }
  };
  return (
    <>
      {loading === "loading" && <CustomCircularProgress />}
      <Outlet />
      {type !== "setting" && allCustomers.length > 0 && <TopNavigation />}
      <Grid
        sx={{
          height: type === "setting" ? "100%" : "87%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {getUIBasedOnLocation(type)}
      </Grid>
    </>
  );
};
export default CenterContent;
