import React, { useEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Button } from '@mui/material';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';  
import { ReactComponent as ExportIcon } from "../../assets/images/export-icon.svg";
import './Chart.scss'
 
/**
 * Formats a numeric value for display, converting large numbers to K, M, or B notation.
 * @param {number} value - The numeric value to format.
 * @returns {string} The formatted value as a string.
 */
const formatValue = (value) => {
  if (value >= 1e9) {
    const formattedValue = (value / 1e9).toFixed(0);
    return `${formattedValue}B`;
  }
  if (value >= 1e6) {
    const formattedValue = (value / 1e6).toFixed(0);
    return `${formattedValue}M`;
  }
  // For values less than 1 million, show two decimal places only if necessary
  const formattedValue = value.toFixed(2);
  return Number(formattedValue) === Math.floor(value) ? value.toFixed(0) : formattedValue;
};


/**
 * AmChartComponent - A React component that renders an interactive chart using amCharts 5.
 * 
 * @param {Object} props
 * @param {string} props.startDate - The start date for the chart data.
 * @param {string} props.endDate - The end date for the chart data.
 * @param {string} props.presetLabel - The label for the current year's data series.
 * @param {Array} props.electricityData - An array of data points for the chart.
 * 
 * @returns {JSX.Element} A React component that displays the chart and an export button.
 */
const AmChartComponent = ({ startDate, endDate, presetLabel, electricityData, customerName,descriptionName }) => {
  const chartDiv = useRef(null); 
  useEffect(() => {

    // Add the license
  am5.addLicense(process.env.REACT_APP_AMCHART_LICENSE); // Use the passed license

  const root = am5.Root.new(chartDiv.current);
  root.setThemes([am5themes_Animated.new(root)]);

  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = end - start; 

   const generateChartData = () => {
            // Sort the electricityData array based on time
            const sortedData = electricityData.sort((a, b) => a.time - b.time);

            return sortedData.map(item => {
                return {
                    date: item.time * 1000, // Convert UNIX timestamp to milliseconds
                    value: item.electricity || 0, // Handle nulls
                    currentYearlabel: formatValue(item.electricity || 0),
                    comparisonValue: item.comparisonValue || 0,
                    lastYearlabel: formatValue(item.comparisonValue || 0),
                };
            }).filter(data => data.date >= start.getTime() && data.date <= end.getTime()); // Filter based on the date range
        };

    let data = generateChartData(); 

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, { 
         panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 40,
        paddingBottom: 38, // Increased padding at the bottom
        cursor: am5xy.XYCursor.new(root, {
          behavior: "none" // Change cursor behavior to "none"
        })
      })
    );

    let baseInterval = { timeUnit: 'day', count: 1 };
    
    if (timeDiff > 31 * 24 * 60 * 60 * 1000) { 
      baseInterval = { timeUnit: 'month', count: 1 };
    } else if (timeDiff > 7 * 24 * 60 * 60 * 1000) { 
      baseInterval = { timeUnit: 'day', count: 4 };
    } else if (timeDiff > 24 * 60 * 60 * 1000) { 
      baseInterval = { timeUnit: 'day', count: 1 }; 
    } else if (timeDiff > 12 * 60 * 60 * 1000 && timeDiff <= 24 * 60 * 60 * 1000) {  
      baseInterval = { timeUnit: 'hour', count: 2 };
    } else {  
      baseInterval = { timeUnit: 'minute', count: 1 };
    }


    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        baseInterval: baseInterval, 
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: true,
          minGridDistance: 10,
        }), 
        min: start.getTime(), // Set the minimum value to the exact start time
        max: end.getTime(), // Set the maximum value to the exact end time
        strictMinMax: true,
        startLocation: 0,
        endLocation: 1,
      })
    );

       // Set up custom axis labels
    xAxis.setAll({
      tooltipDateFormat: "HH:mm",
      dateFormats: {
        minute: "HH:mm",
        hour: "HH:mm",
        day: 'HH:mm', // Display hours and minutes for days in 24-hour format as well
      },
      periodChangeDateFormats: {
        minute: "HH:mm",
        hour: "HH:mm",
      day: 'yyyy/MM/dd', // Full format for larger date ranges
      },
      gridIntervals: [
        { timeUnit: "hour", count: 1 }
      ]
    });
 // Set the date formats for the x-axis based on timeDiff
  if (timeDiff <= 24 * 60 * 60 * 1000) { // Less than 1 day
    xAxis.set('dateFormats', {
     minute: "HH:mm",
        hour: "HH:mm",
        day: 'HH:mm', // Display hours and minutes for days in 24-hour format as well 
    });
  } else {
    xAxis.set('dateFormats', {
      day: 'yyyy/MM/dd', // Full format for larger date ranges
      month: 'yyyy/MM', // Month format
      year: 'yyyy', // Year format
    });

    // Optionally set periodChangeDateFormats for better formatting
    xAxis.set('periodChangeDateFormats', {
      day: 'yyyy/MM/dd',
      month: 'yyyy/MM',
      year: 'yyyy',
    });
  }

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Set label font size for X-axis
    const xRenderer = xAxis.get('renderer');
    if (xRenderer) {
      const labelsTemplate = xRenderer.labels.template;
      labelsTemplate.setAll({
        fill: "#525252", // Label color
        fontSize: 12, // Set your desired font size here
      });
    }

    const yRenderer = yAxis.get('renderer');
    if (yRenderer) {
      const labelsTemplate = yRenderer.labels.template;
      labelsTemplate.setAll({
        fill:"#525252", // Label color
        fontSize: 12, // Label font size
      });

      labelsTemplate.adapters.add("text", (text, target) => {
        const dataItem = target.dataItem;
        if (dataItem) {
          const value = dataItem.get("value");
          return value !== undefined ? formatValue(value) : text;
        }
        return text; // Return original text if value is undefined
      });
    } 
     
     const tooltip = am5.Tooltip.new(root, {
        getStrokeFromSprite: false,
        getFillFromSprite: false,
        pointerOrientation: "horizontal",
      });

      tooltip.get("background").setAll({
        fill: "#fff",
        stroke: "#f7f7f7",
        strokeWidth: 2,
        // Add the following properties for drop shadow
        shadowColor: am5.color(0x161616),
        shadowBlur: 8,
        shadowOffsetX: 0,
        shadowOffsetY: 2,
        shadowOpacity: 0.16
      });

 
// Use a template string for dynamic HTML content
// Set up the tooltip to use a function to access the current data item's properties
tooltip.label.adapters.add("html", (text, target) => {
  const dataItem = target.dataItem;

  if (dataItem) {
    const dateX = new Date(dataItem.get("valueX"));
    const formattedDate = root.dateFormatter.format(dateX, "yyyy-MM-dd HH:mm");
    const { currentYearlabel, lastYearlabel } = dataItem?.dataContext;

    let tooltipContent = `
      <div class="custom-chart-tooltip">
        <div class="date">${formattedDate}</div>
    `;

    if (series.get("visible")) {
      tooltipContent += `<div class="currentData"><strong>${presetLabel}: </strong>${currentYearlabel}</div>`;
    }

    if (comparisonSeries.get("visible")) {
      tooltipContent += `<div class="lastYearData"><strong>Previous period: </strong>${lastYearlabel}</div>`;
    }

    tooltipContent += `</div>`;

    return tooltipContent;
  }
  return text;
});


    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Current Year Data',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: tooltip, 
      })
    );
// Set tooltip to the series
series.set("tooltip", tooltip);   

    const comparisonSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Last Year Data',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'comparisonValue',
        valueXField: 'date',
        stroke: "#AE4787",
         tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0
        })
      })
    );

    series.data.setAll(data);
    comparisonSeries.data.setAll(data);

    // Add bullet for main series
    series.bullets.push(() => {
      let circle = am5.Circle.new(root, {
        radius: 6, 
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2,   
          interactive: true,
          fill: "#00539A",
          opacity: 0
      });
       circle.states.create("default", {
          opacity: 0
        });
        circle.states.create("hover", {
          opacity: 1
        });
        
      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    // Add bullet for comparison series
    comparisonSeries.bullets.push(() => {
      let circle = am5.Circle.new(root, {
         radius: 6, 
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2,
        opacity:0,  
          interactive: true,
          fill: "#AE4787", 
      });
 circle.states.create("default", {
          opacity: 0
        });
        circle.states.create("hover", {
          opacity: 1
        });
      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    const cursor = chart.get("cursor");
    cursor.setAll({
      xAxis: xAxis,
      yAxis: yAxis
    }); 
 
    cursor.lineY.set("visible", false); 
    
     const updateXAxis = (event) => {
      const delta = event?.originalEvent?.deltaY; 

      // Update zoom behavior only if timeDiff is less than or equal to 24 hours
      if (timeDiff <= 24 * 60 * 60 * 1000) {
        let newBaseInterval;
        if (delta < 0) {
          // Zoom in
          // newBaseInterval = { timeUnit: 'minute', count: 30 };
        } else { 
          // Zoom out to show exactly the same start and end times
          xAxis.set('min', start.getTime());
          xAxis.set('max', end.getTime());
          // newBaseInterval = { timeUnit: 'hour', count: 1 };
        }

        xAxis.set('baseInterval', newBaseInterval);
        
        // Ensure the chart shows the full range when zooming out
        if (delta > 0) {
          chart.zoomOut();
        }
      }

       // Update zoom behavior only if timeDiff is less than 30 days
      if (timeDiff < 30 * 24 * 60 * 60 * 1000 ) {
           let newBaseInterval; 
            if(timeDiff > 31 * 24 * 60 * 60 * 1000 ||  timeDiff > 7 * 24 * 60 * 60 * 1000)
            { 
              // Apply zoom based on mouse wheel movement
              if (delta < 0) {
                // Zoom in
                newBaseInterval = { timeUnit: 'day', count: 1 };
              } else {
                // Zoom out
                newBaseInterval = { timeUnit: 'day', count: 4 };
              }
              xAxis.set('baseInterval', newBaseInterval); 
            }else if(timeDiff > 12 * 60 * 60 * 1000 && timeDiff <= 24 * 60 * 60 * 1000){
               // Apply zoom based on mouse wheel movement
              if (delta < 0) {
                // Zoom in
                newBaseInterval = { timeUnit: 'hour', count: 1 };
              } else { 
                // Zoom out
                newBaseInterval = { timeUnit: 'hour', count: 2 };
              }
              xAxis.set('baseInterval', newBaseInterval); 
            }
      } 
    };

    

     // Modify the legend creation process
    const legendContainer = root.container.children.push(am5.Container.new(root, {
      layout: root.horizontalLayout,
      y: am5.percent(98),
      centerX: am5.p50,
      x: am5.p50,
    }));

    // Create legend for series
    const createLegendItem = (name, color, series) => {
      const legendItem = legendContainer.children.push(am5.Container.new(root, {
        layout: root.horizontalLayout,
        paddingLeft: 6,
        paddingRight: 6,
        cursorOverStyle: "pointer",
      }));

      const innerContainer = legendItem.children.push(am5.Container.new(root, {
        layout: root.verticalLayout,
        centerY: am5.p50,
      }));

      const square = innerContainer.children.push(am5.Rectangle.new(root, {
        width: 16,
        height: 16,
        fill: am5.color(color),
        stroke: am5.color(0xffffff),
        strokeWidth: 2
      }));

      const label = innerContainer.children.push(am5.Label.new(root, {
        text: name,
        paddingTop: -15,
        paddingLeft: 22,
        fontSize: 12,
        fill: am5.color(0x000000)
      }));

      // Add click event to toggle series visibility
      legendItem.events.on("click", () => {
        if (series.get("visible")) {
          series.hide();
          square.set("fill", am5.color(0xcccccc));
          label.set("opacity", 0.5);
        } else {
          series.show();
          square.set("fill", am5.color(color));
          label.set("opacity", 1);
        }
        
        // Force tooltip update
        chart.series.each((s) => {
          if (s.get("tooltip")) {
            s.get("tooltip").hide(0);
          }
        });
      });

      return legendItem;
    };

    // Add series legends
    createLegendItem(`${presetLabel}`, "#00539A", series);
    createLegendItem('Previous period', "#AE4787", comparisonSeries);

 // Create Y-axis legend container
    const yAxisLegendContainer = root.container.children.push(am5.Container.new(root, {
      layout: root.verticalLayout,
      paddingTop: 10,
      paddingLeft: 15,
    }));

    // Create Y-axis legends
    const createYAxisLegendItem = (name, color) => {
      const legendItem = yAxisLegendContainer.children.push(am5.Container.new(root, {
        layout: root.horizontalLayout,
        paddingLeft: 15, 
        paddingRight: 15,
        y: am5.percent(200) , // Positioning it just below the x-axis
        centerX: am5.p50,
        x:  am5.p50 , 
      }));

      const innerContainer = legendItem.children.push(am5.Container.new(root, {
        layout: root.verticalLayout,
        centerY: am5.p50,
      }));

     

      innerContainer.children.push(am5.Label.new(root, { 
        rotation: -90, // Rotate labels to vertical
        text: name,
        paddingTop: -40,
        paddingLeft: 100,
        fontSize: 12, 
        fill: "#525252"
      }));

      return legendItem;
    };

    // Add Y-axis legends
    createYAxisLegendItem('Energy (kWh)', "#00539A"); 
    
    // Add scrollbar
    //  const scrollbarX = am5.Scrollbar.new(root, { orientation: "horizontal" });
    // chart.set("scrollbarX", scrollbarX);


    chart.events.on("wheel", updateXAxis); 
    // scrollbarX.events.on("rangechanged",updateXAxis)
    chart.appear(1000, 100);
    let previousBulletSprites = [];

    // Add this new function to handle mouse leave
    const handleMouseLeave = () => {
      previousBulletSprites.forEach(sprite => sprite.unhover());
      previousBulletSprites = [];
    };

    cursor.events.on("cursormoved", () => {
      previousBulletSprites.forEach(sprite => sprite.unhover());
      previousBulletSprites = [];

      chart.series.each(series => {
        const dataItem = series?.get("tooltip")?.dataItem;
        if (dataItem) {
          const bulletSprite = dataItem?.bullets[0]?.get("sprite");
          bulletSprite.hover();
          previousBulletSprites.push(bulletSprite);
        }
      });
    });

    // Add event listener for mouse leave on the chart's container
    chart.root.dom.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      root.dispose();
      // Remove the event listener when the component unmounts
      chart.root.dom.removeEventListener("mouseleave", handleMouseLeave);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, electricityData]);


const exportToCSV = (electricityData) => {
  // Group unique siteNames and sort them alphabetically
  const uniqueSiteNames = [...new Set(electricityData.map(item => item.siteName))].sort();
  const siteName = uniqueSiteNames.join(', '); // Join all unique site names with a comma and space

  // const customerName = customerName; // Replace with actual customer name
  const sourceType = "Electricity";
  const country = "Japan"; // Replace with actual country name

  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = end - start;

  // Format the date range correctly
  const formatDate = (date, includeTime = true) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if (includeTime) {
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}/${month}/${day} ${hours}:${minutes}`;
    }
    return `${year}/${month}/${day}`;
  };

  const startDateFormatted = formatDate(start);
  const endDateFormatted = formatDate(end);

  let csvRows = [
    `Customer,${customerName},,`,
    `Description,${descriptionName},,`,
    `Source type,${sourceType},,`,
    `Date Range,${startDateFormatted} ~ ${endDateFormatted},,`,
  ];

  let dataFormat;
  if (timeDiff <= 24 * 60 * 60 * 1000) {
    // For Today, yesterday, and the last 24 Hours
    dataFormat = 'hourly';
    csvRows.push('Date and Time,kWh,Date and Time,kWh');
  } else if (timeDiff <= 31 * 24 * 60 * 60 * 1000) {
    // For this week, last week, this month, and last month
    dataFormat = 'daily';
    csvRows.push('Date,kWh,Date,kWh');
  } else {
    // For year and last year
    dataFormat = 'monthly';
    csvRows.push('Year and Month,kWh,Year and Month,kWh');
  }

  const formatDataRow = (item1, item2) => {
    const formatValue = (value) => value !== null && value !== undefined ? value.toFixed(1) : 'null';

    if (dataFormat === 'hourly') {
      const date1 = formatDate(new Date(item1.time * 1000));
      const value1 = formatValue(item1.electricity);
      const date2 = item2 ? formatDate(new Date(item2.time * 1000)) : '';
      const value2 = item2 ? formatValue(item2.electricity) : '';
      return `${date1},${value1},${date2},${value2}`;
    } else if (dataFormat === 'daily') {
      const date1 = formatDate(new Date(item1.time * 1000), false);
      const value1 = formatValue(item1.electricity);
      const date2 = item2 ? formatDate(new Date(item2.time * 1000), false) : '';
      const value2 = item2 ? formatValue(item2.electricity) : '';
      return `${date1},${value1},${date2},${value2}`;
    } else {
      const date1 = new Date(item1.time * 1000);
      const yearMonth1 = `${date1.getFullYear()}/${String(date1.getMonth() + 1).padStart(2, '0')}`;
      const value1 = formatValue(item1.electricity);
      let yearMonth2 = '', value2 = '';
      if (item2) {
        const date2 = new Date(item2.time * 1000);
        yearMonth2 = `${date2.getFullYear()}/${String(date2.getMonth() + 1).padStart(2, '0')}`;
        value2 = formatValue(item2.electricity);
      }
      return `${yearMonth1},${value1},${yearMonth2},${value2}`;
    }
  };

  // Group data if necessary
  let groupedData = electricityData;
  if (dataFormat === 'daily') {
    groupedData = groupDataByDay(electricityData);
  } else if (dataFormat === 'monthly') {
    groupedData = groupDataByMonth(electricityData);
  }

  // Add data rows
  for (let i = 0; i < groupedData.length; i += 2) {
    const currentItem = groupedData[i];
    const nextItem = groupedData[i + 1];
    csvRows.push(formatDataRow(currentItem, nextItem));
  }

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Generate file name based on specifications
  const startPeriod = start.toISOString().split('T')[0].replace(/-/g, '');
  const endPeriod = end.toISOString().split('T')[0].replace(/-/g, '');
  const fileName = `${country}_${sourceType}_${startPeriod}_${endPeriod}.csv`;

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


// Helper function to group data by day
const groupDataByDay = (data) => {
  const groupedData = {};
  data.forEach(item => {
    const date = new Date(item.time * 1000);
    const dateKey = date.toISOString().split('T')[0];
    if (!groupedData[dateKey]) {
      groupedData[dateKey] = { time: item.time, electricity: 0, count: 0 };
    }
    if (item.electricity !== null && item.electricity !== undefined) {
      groupedData[dateKey].electricity += item.electricity;
      groupedData[dateKey].count++;
    }
  });
  return Object.values(groupedData).map(item => ({
    time: item.time,
    electricity: item.count > 0 ? item.electricity / item.count : null
  }));
};

// Helper function to group data by month
const groupDataByMonth = (data) => {
  const groupedData = {};
  data.forEach(item => {
    const date = new Date(item.time * 1000);
    const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    if (!groupedData[monthKey]) {
      groupedData[monthKey] = { time: item.time, electricity: 0, count: 0 };
    }
    if (item.electricity !== null && item.electricity !== undefined) {
      groupedData[monthKey].electricity += item.electricity;
      groupedData[monthKey].count++;
    }
  });
  return Object.values(groupedData).map(item => ({
    time: item.time,
    electricity: item.count > 0 ? item.electricity / item.count : null
  }));
};

return (
  <>
  <Button onClick={() => exportToCSV(electricityData)} className="exportButton" endIcon={<ExportIcon/> }>  Export </Button> 
    <div
          id="chartdiv"
          ref={chartDiv}
          style={{ width: '100%', height: '659px' }}
      />
  </>
);
};

export default AmChartComponent;