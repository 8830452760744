import React, { useState, useMemo, useRef, useEffect } from "react";
import Table from "@mui/material/Table";
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Stack,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";
import { ReactComponent as LocationIcon } from "../../assets/images/LocationOutlined.svg";
import { ReactComponent as EmsLogo } from "../../assets/images/GroupEms.svg";
import { ReactComponent as BellIcon } from "../../assets/images/SmallAlarm.svg";
import { ReactComponent as ChartIcon } from "../../assets/images/ChartColumn.svg";
import "./LandingPages.scss";

const SiteGrid = (props) => {
  const { t } = useTranslation();
  const {
    landingPage: {
      homeTableHeaderStructure,
      siteListTableHeader,
      gridLoadingText,
      noResultFound,
      cards,
    },
  } = t("mainContainer");
  const {
    siteLength,
    tableData,
    tooltipColumns = [0, 1],
    siteListCount,
  } = props;

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "1em",
    },
  })(Tooltip);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [itemsToShow, setItemsToShow] = useState(5); // Number of items to show initially
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true); // Check if there are more items to load
  const observer = useRef();
  const loadMoreRef = useRef();
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedSites = useMemo(() => {
    if (!tableData) return [];
    const sortFunction = (a, b) => {
      if (orderBy === "siteName") {
        return order === "asc"
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy]);
      } else {
        return order === "asc"
          ? (a[orderBy] || 0) - (b[orderBy] || 0)
          : (b[orderBy] || 0) - (a[orderBy] || 0);
      }
    };

    return tableData.slice(0, itemsToShow).sort(sortFunction); // Only show items based on itemsToShow
  }, [tableData, order, orderBy, itemsToShow]);

  const loadMoreItems = () => {
    if (itemsToShow > siteListCount) {
      setHasMoreItems(false);
    } else if (isLoading || !hasMoreItems) return;
    setIsLoading(true);
    // Show the loader for 2 seconds
    setTimeout(() => {
      const newItemsToShow = itemsToShow + 5;
      if (newItemsToShow >= siteListCount) {
        setHasMoreItems(false);
      }
      setItemsToShow(newItemsToShow);
      setIsLoading(false);
    }, 1200);
  };

  useEffect(() => {
    const currentObserver = loadMoreRef?.current;
    if (!currentObserver) return;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    };
    observer.current = new IntersectionObserver(handleIntersect, options);
    observer.current.observe(currentObserver);
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMoreItems]);

  const renderTableCellContent = (content, colIndex) => {
    return tooltipColumns?.includes(colIndex) ? (
      <CustomTooltip title={content} placement="bottom-start">
        <span>{content}</span>
      </CustomTooltip>
    ) : (
      <>{content}</>
    );
  };

  const dummyImage = "https://via.placeholder.com/50";

  return (
    <div className="site-grid-container emailNotificationBox">
      <TableContainer
        component={Paper}
        sx={{
          marginLeft: "0",
          marginRight: "0",
          "&.MuiTableContainer-root": { height: "100%" },
          "&.MuiPaper-root": { height: "100%" },
        }}
      >
        <Table
          aria-label="custom table"
          className="customSiteGrid"
          sx={{ tableLayout: "fixed" }}
        >
          <TableHead className="siteGridTableHead">
            <TableRow sx={{ height: "40px" }}>
              {siteListTableHeader?.map((header, colIndex) => {
                const isActiveColumn = orderBy === header.accessor;
                const headerBackgroundColor = isActiveColumn
                  ? "#C6C6C6"
                  : "#E0E0E0";
                const headerWidth =
                  header.accessor === "actionMenu" ? "5%" : "auto";
                return (
                  <TableCell
                    key={`table_header_action_${header.label}`}
                    sortDirection={isActiveColumn ? order : false}
                    className="siteGridHeadCell"
                    sx={{
                      backgroundColor: headerBackgroundColor,
                      cursor: header?.label ? "pointer" : "default",
                      position: "relative",
                      width: headerWidth,
                      overflow: "hidden", // Hide content in the actionMenu column
                      "&:hover": {
                        backgroundColor: "#d3d3d3",
                      },
                    }}
                    onClick={() =>
                      header?.label && handleRequestSort(header.accessor)
                    }
                  >
                    {header?.label && (
                      <div className="siteGridHeaderDiv">
                        <div>{homeTableHeaderStructure[colIndex]}</div>
                        <TableSortLabel
                          active={isActiveColumn}
                          direction={isActiveColumn ? order : "asc"}
                          sx={{ marginLeft: "5px" }}
                        >
                          {" "}
                        </TableSortLabel>
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSites?.map((row, index) => (
              <TableRow
                key={`table_row_${row?.siteName}`}
                sx={{
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                {siteListTableHeader?.map((headerVal, headerIndx) => {
                  if (headerVal?.accessor === "siteName") {
                    return (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        className="siteGridNameCell"
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        <img
                          src={row?.image || dummyImage}
                          alt="Site"
                          className="siteGridImageStyle"
                        />
                        <div className="siteGridNameAndLocation">
                          <Typography className="body1">
                            {row?.siteName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {row?.state} , Japan
                          </Typography>
                        </div>
                      </TableCell>
                    );
                  } else if (headerVal?.accessor === "electricity") {
                    return (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        className="siteGridElectricityCell"
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        <div className="siteGridValueAndUnit">
                          <Typography className="body1">
                            {row?.electricity}
                            <span className="accordionSubHeaderStyle">
                              {cards?.electricityUnit}
                            </span>
                          </Typography>
                        </div>
                      </TableCell>
                    );
                  } else if (headerVal?.accessor === "actionMenu") {
                    return (
                      <TableCell
                        key={`column_${index}_${headerVal?.accessor}`}
                        className="siteGridActionMenuCell"
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        <div className="action-menu-icons">
                          <IconButton size="small">
                            <LocationIcon />
                          </IconButton>
                          <IconButton size="small">
                            <ChartIcon />
                          </IconButton>
                          <IconButton size="small">
                            <BellIcon />
                          </IconButton>
                          <IconButton size="small">
                            <EmsLogo />
                          </IconButton>
                        </div>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={`column_${row?.id}_${headerIndx}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          height: "auto",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        datacustomtablecellid={headerVal?.accessor || ""}
                      >
                        {renderTableCellContent(
                          row?.[headerVal?.accessor],
                          headerIndx
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {siteLength === 0 && (
          <Stack className="noItemsContainer">
            <Typography>{noResultFound}</Typography>
          </Stack>
        )}
      </TableContainer>
      {isLoading && hasMoreItems && (
        <div className="loading-indicator">
          <Typography>{gridLoadingText}</Typography>
        </div>
      )}
      <div ref={loadMoreRef} />
    </div>
  );
};

export default SiteGrid;
