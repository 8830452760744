import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import CustomerNavigationDropdowns from './CustomerNavigationDropdowns';
import { leftNavStyles } from './LeftNavigationsStyles';
import { ReactComponent as Marker } from "../../../assets/images/Location.svg";
import CustomModal from '../../../common/commonPopup/CustomModal';
import { getTotalForAll } from '../../langing-page/MockApi';
import GMap from '../../setting/CustomGoogleMap';
const CustomerLeftNavigation = () => {
  const [breadcrumbData, setbreadcrumbData] = useState();
  const { selectedCategory, selectedSites, selectedAreas, updateBeadcrumbs } = useSelector(state => state.customer);
  const { accordionList } = useSelector((state) => state.customerInfo);
  const [commonGmapOpen, setCommonGmapOpen] = useState(false);
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { totalElectricity, totalAlarms } = getTotalForAll(accordionList);
  const areas = breadcrumbData?.selectedAreas
    ?.slice() // Create a shallow copy to avoid mutating the original array
    .sort() // Sort the areas alphabetically
    .join(', ');

  const sites = breadcrumbData?.selectedSites
    ?.map(site => site.name) // Extract names from the site objects
    .sort() // Sort the site names alphabetically
    .join(', '); // Join names with comma

  useEffect(() => {
    if (updateBeadcrumbs && Object.keys(selectedCategory).length > 0 && selectedSites.length > 0 && selectedAreas.length > 0) {
      setbreadcrumbData({
        selectedCategory: selectedCategory,
        selectedSites: selectedSites,
        selectedAreas: selectedAreas
      });
    } else if (updateBeadcrumbs && Object.keys(selectedCategory).length > 0 && selectedSites.length == 0) {
      setbreadcrumbData({
        selectedCategory: selectedCategory,
        selectedSites: selectedSites,
        selectedAreas: selectedAreas
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBeadcrumbs, selectedSites, selectedCategory, selectedAreas]);
  const handleMarkerClick = () => {
    setCommonGmapOpen(true)
  }
  const handleClose = () => {
    setCommonGmapOpen(false);
  };
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={leftNavStyles.box}
      >
        <Breadcrumb primaryLink={breadcrumbData?.selectedCategory?.displayName} secodaryLink={areas} activeLink={sites} />
        <CustomerNavigationDropdowns setbreadcrumbData={setbreadcrumbData} breadcrumbData={breadcrumbData} />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={leftNavStyles.marker}  >
        <Marker onClick={handleMarkerClick} />
      </Box>
      <CustomModal
        title={selectedCategory?.displayName}
        open={commonGmapOpen}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg"
        contentPadding={true}
      >
        {!accordionList && <div>Something went wrong</div>}
        {accordionList?.length &&
          <GMap
            locat={accordionList}
            isTopNavigationMap={true}
            setCommonGmapOpen={setCommonGmapOpen}
          />}
      </CustomModal>
    </React.Fragment>)
}

export default CustomerLeftNavigation;
