import React from 'react';
import { Box } from '@mui/material';
import CustomerLeftNavigation from './leftNavigations/Index';
import DateRangeCalendar from './DateRangeCalendar';
import { topNavigationStyles } from './TopNavigationStyles';

const TopNavigation = () => {
  return (
    <Box
      sx={topNavigationStyles.box}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <CustomerLeftNavigation />
      {/* Disable Date Picker */}
      {/* <DateRangeCalendar /> */}
    </Box>)
}
export default TopNavigation;
