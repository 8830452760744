import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from "axios";
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import { Box, Grid, Divider } from '@mui/material';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { getSiteImage } from '../../services/apiConfig';
import blueMarker from '../../assets/images/blueMarker.svg';
import redMarker from '../../assets/images/redMarker.svg';
import buildingImg from '../../assets/images/SiteDefaultImage.svg';
import { ReactComponent as AddressMarker } from '../../assets/images/AddressMarker.svg';
import usePost from '../../common/hook/usePost'; 

import { padding } from '@mui/system';

// Replace 'DEMO_MAP_ID' with your actual map ID
const MAP_ID = 'DEMO_MAP_ID'; 
 

/**
 * PoiMarkers component handles rendering and clustering of markers on the map.
 * @param {Object} props - Component props containing 'pois' array of POI locations.
 */
const PoiMarkers = props => {
	const map = useMap();
	const [markers, setMarkers] = useState({});
	const [selectedMarker, setSelectedMarker] = useState(null); // State to hold selected marker
	const token = localStorage?.getItem("accessToken");
 


	useEffect(() => {
		if (!map) return;

		const bounds = new window.google.maps.LatLngBounds();
		Object.values(markers).forEach(marker => {
			if (marker && marker.getPosition) {
				bounds.extend(marker.getPosition());
			}
		});

		if (!bounds.isEmpty()) {
			map.fitBounds(bounds);
		}
	}, [map, markers]);

	useEffect(() => {
		if (!map) return;

		const geocoder = new window.google.maps.Geocoder();
		const newMarkers = {};

		const geocodeRequests = props.pois.map(poi => {
			let geocodeRequest = {};
			if (poi.address) {
				geocodeRequest = { address: `${poi.address}, ${poi.country}` };
			} else if (poi.postalCode) {
				geocodeRequest = {
					componentRestrictions: {
						postalCode: poi.postalCode,
						country: poi.country,
					},
				};
			}
			return geocodeRequest;
		});

		Promise.all(
			geocodeRequests.map(
				req => new Promise(resolve => geocoder.geocode(req, resolve))
			)
		)
			.then(results => {
				results.forEach((result, index) => {
					const poi = props.pois[index];
					if (result && result.length > 0) {
						const location = result[0].geometry.location;

						const labelOrigin = new window.google.maps.Point(64, 15);
						const label = {
							text: poi.siteName,
							color: poi.isClicked ? '#E34935' : '#0F62FE',
							fontSize: '14px',
							fontWeight: 'bold',
							className:"marker-custom-label"
						};

						const markerIcon = {
							url: poi.isClicked ? redMarker : blueMarker,
							labelOrigin: labelOrigin,
						};

						const marker = new window.google.maps.Marker({
							position: location,
							map: map,
							icon: markerIcon,
							label: label,
						});

					 
					
					const infowindow = new window.google.maps.InfoWindow();
					
					marker.addListener('mouseover', async () => {
						if(poi?.imageUrl)
						{const payload = {
							"imageUrl": poi?.imageUrl
						};
					
						try {
							const response = await axios.post(getSiteImage, payload, {
								headers: {
									Authorization: `Bearer ${token}`,
									Accept: "application/json",
									"Content-Type": "application/json",
								},
							});
					
							// Update state
							await new Promise(resolve => {  
								resolve();  // Resolves the promise immediately after state is set
							});
					
							// Update tooltip content with the new image
							infowindow.setContent(`
								<div class="site-tooltip">  
									<div class="site-default-img"><img src="${response?.data?.signedUrl}" alt="default site"/></div>
									<div class="site-info">
										<div class="site-title">${poi.siteName}</div>
										<div class="site-desc">${poi.description}</div>
									</div>
								</div>
							`);
					
							// Open the info window
							infowindow.open(map, marker);
					
							// Update marker label style
							marker.setLabel({
								text: marker.getLabel().text,
								color: '#0043CE',
								fontWeight: 'bold',
								className:"marker-custom-label"
							});
					
						} catch (error) {
							console.error('Error fetching site image:', error);
						}}else{

							// Update tooltip content with the new image
							infowindow.setContent(`
								<div class="site-tooltip">  
									<div class="site-default-img"><img src="${buildingImg}" alt="default site"/></div>
									<div class="site-info">
										<div class="site-title">${poi.siteName}</div>
										<div class="site-desc">${poi.description}</div>
									</div>
								</div>
							`);

								// Open the info window
								infowindow.open(map, marker);
					
								// Update marker label style
								marker.setLabel({
									text: marker.getLabel().text,
									color: '#0043CE',
									fontWeight: 'bold',
									className:"marker-custom-label"
								});
						
						}
					});
					
						marker.addListener('mouseout', () => {
							infowindow.close();
							if (!marker?.isClicked) {
								marker.setLabel({
									text: marker.getLabel().text,
									color: '#0F62FE',
									fontWeight: 'bold',
									className:"marker-custom-label"
								});
							}
						});

						marker.addListener('click', async () => {

					
							  

							Object.values(newMarkers).forEach(m => {
								m.setIcon({
									url: blueMarker,
									labelOrigin: labelOrigin,
								});
								m.setLabel({
									text: m.getLabel().text,
									color: '#0F62FE',
									className:"marker-custom-label"
								});
								m.set('isClicked', false);
							});

							marker.setIcon({
								url: redMarker,
								labelOrigin: labelOrigin,
							});
							marker.setLabel({
								text: marker.getLabel().text,
								color: '#E34935',
								fontWeight: 'bold',
								className:"marker-custom-label"
							});
							marker.set('isClicked', true);
							props.onMarkerClick(poi);
							setSelectedMarker(poi); // Set selected marker information
						});

						newMarkers[poi.key] = marker;
					} else {
						console.error(`Geocode was not successful for ${poi.address}`);
					}
				});

				setMarkers(newMarkers);

				const bounds = new window.google.maps.LatLngBounds();
				Object.values(newMarkers).forEach(marker => {
					if (marker && marker.getPosition) {
						bounds.extend(marker.getPosition());
					}
				});
				if (!bounds.isEmpty()) {
					map.fitBounds(bounds);
				}
			})
			.catch(error => {
				console.error('Geocoding error:', error);
			});

		return () => {
			Object.values(newMarkers).forEach(marker => {
				marker.setMap(null);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.pois, map]);

	return null;
};

/**
 * GMap component renders the Google Map with APIProvider and handles markers using PoiMarkers component.
 */
const GMap = ({ locat }) => {
	const [showSlider, setShowSlider] = useState(false); // State to control slider visibility
	const [selectedMarkerInfo, setSelectedMarkerInfo] = useState(null); // State to hold selected marker information

	const [currentSiteImage,setCurrentSiteImage]=useState()
	const {
		loading: siteImageLoading,
		error: siteImageError,
		data: siteImageData,
		postData: doPostSiteImage,
	  } = usePost();
	  
	

	
	const handleMarkerClick = async ( markerInfo) => {
		// When a marker is clicked, show the slider and set selected marker info
		setShowSlider(true);
		setSelectedMarkerInfo(markerInfo);
		const payload = {
			"imageUrl": markerInfo?.imageUrl
		  } 

		  await doPostSiteImage(getSiteImage, payload); 
	};

	
	useEffect(() => {
		if(!siteImageLoading&&siteImageData&&!siteImageError){
		   setCurrentSiteImage(siteImageData)
		}
   }, [siteImageLoading, siteImageError,siteImageData]);
   
	return (
		<div className="custom-google-map">
			<Box>
				<Grid container>
					{showSlider && (
						<Grid item xs={2} md={2}>
							<Box
								sx={{ height: '60vh', overflowY: 'auto', paddingTop: '16px' }}
							>
								<Box>
									<div class="site-default-img">
										<img src={siteImageData?.signedUrl||buildingImg} alt="default site" />
									</div>

									{selectedMarkerInfo && (
										<div className="site-info">
											<div className="site-title">
												{selectedMarkerInfo.siteName}
											</div>
											<p className="site-description"> 
												{selectedMarkerInfo.description}
											</p>
											<div className="d-line" />
											<p className="site-address">
												<span className="icon">
													<AddressMarker />
												</span>
												{selectedMarkerInfo.address},<br />
												{selectedMarkerInfo.postalCode}
											</p>
										</div>
									)}
								</Box>
							</Box>
						</Grid>
					)}

					<Grid item xs={showSlider ? 10 : 12} md={showSlider ? 10 : 12}>
						<Box
							style={{
								width: 'auto',
								height: '34.188rem',
								padding: showSlider ? '16px 16px 16px 0' : '16px',
							}}
						>
							<APIProvider apiKey={process.env.REACT_APP_API_KEY}>
								<Map
									defaultZoom={8}
									defaultCenter={{ lat: 36.2048, lng: 138.2529 }} // Example default center
									gestureHandling={'greedy'}
									onCameraChanged={ev =>
										console.log(
											'camera changed:',
											ev.detail.center,
											'zoom:',
											ev.detail.zoom
										)
									}
									mapId={MAP_ID}
								>
									<PoiMarkers pois={locat} onMarkerClick={handleMarkerClick} />
								</Map>
							</APIProvider>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box></Box>
		</div>
	);
};

export default GMap;
