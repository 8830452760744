import React from "react";
import { Tabs, Tab, Grid } from "@mui/material";
import "../../components/setting/setting.scss";
const CustomTabs = ({
  tabData,
  activeTab,
  onTabChange,
  tabStyles,
  tabContentWidthLg,
  tabContentWidthMd,
  tabContentWidthSm,
  mainGridWidth = 6,
  smallDevice = 12,
}) => {
  const handleTabChange = (event, newValue) => {
    onTabChange(newValue);
  };
  return (
    <>
      <Grid
        item
        lg={mainGridWidth}
        md={8}
        xs={smallDevice}
        className="tabHeight"
        gap={2}
        xl={5.1}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={tabStyles?.tabs}
          className="tabPanelStyle"
        >
          {tabData?.map((tab, index) => (
            <Tab key={index} label={tab?.label} sx={tabStyles?.tab} />
          ))}
        </Tabs>
      </Grid>
      {tabData?.map((tab, index) => (
        <TabPanel
          key={index}
          value={activeTab}
          index={index}
          sx={tabStyles?.tab}
          tabContentWidthLg={tabContentWidthLg}
          tabContentWidthMd={tabContentWidthMd}
          tabContentWidthSm={tabContentWidthSm}
        >
          {tab?.content}
        </TabPanel>
      ))}
    </>
  );
};

const TabPanel = ({
  children,
  value,
  index,
  tabContentWidthLg,
  smallDevice,
}) => {
  return (
    <Grid
      item
      lg={tabContentWidthLg}
      xs={smallDevice}
      // md={tabContentWidthMd}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className="tabpanel-Parent"
    >
      {value === index && <>{children}</>}
    </Grid>
  );
};

export default CustomTabs;
