import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { consolidateByState, getTotalForAll } from "./MockApi";
import AlarmCard from "./AlarmCard";
import ElectricityCard from "./ElectricityCard";
import PrefectureAccordion from "./PrefectureAccordion";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";
import { landingPageStyle } from "./LandingPageStyle";
import { updateAccordionList } from "../../redux/slices/customerSlice";
import { electricityConsumptionURL, getHomeAllSiteList } from "../../services/apiConfig";
import useFetch from "../../common/hook/useFetch";
import NoPrefecture from "./NoPrefecture";
import usePost from '../../common/hook/usePost';
import { getSingleStateName } from "../../common/utils/CommonUtils";
import LandingPageSearchBar from "./LandingPageSearchBar";
import { prefectures } from "../setting/CustomerData";

function LandingPage() {
  const [consolidatedData, setConsolidatedData] = useState({});
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [siteListCount, setSiteListCount] = useState("blank");
  const [searchType, setSearchType] = useState("area")
  const [searchQuery, setSearchQuery] = useState('')
  const { accordionList } = useSelector((state) => state.customerInfo);
  const [data, setData] = useState(accordionList);
  const [resetKey, setResetKey] = useState(0)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { recentSearchId, selectedCategory, isUpdateRecentSearchId } = useSelector(
    (state) => state?.customer
  );
  const {
    landingPage: { cards, siteListTableHeader, accordionHeader, noResultFound, noDataText },
  } = t("mainContainer");

  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost();

  const {
    data: fetchsitesData,
    error: fetchsiteError,
    loading: fetchsiteErrorLoading,
    fetchData: getSitesDataList,
  } = useFetch();

  const { totalElectricity, totalAlarms } = getTotalForAll(data);

  useEffect(() => {
    if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
      if (fetchsitesData?.length === 0 || !fetchsitesData?.some(obj => obj?.hasOwnProperty('state'))) {
        setSiteListCount('empty')
      }
    }
  }, [fetchsiteErrorLoading, fetchsitesData, fetchsiteError]);

  useEffect(() => {
    if (recentSearchId) {
      const currentEpochTime = Math.floor(Date.now() / 1000);
      const secondsIn24Hours = 24 * 60 * 60;
      const endingDate = currentEpochTime;
      const startingDate = endingDate - secondsIn24Hours;
      const recentSearchPayload = {
        searchId: recentSearchId,
        startDate: startingDate,
        endDate: endingDate
      };
      doPostPrefectureApi(electricityConsumptionURL, recentSearchPayload);
      setSiteListCount("blank");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentSearchId, isUpdateRecentSearchId]);

  useEffect(() => {
    if (selectedCategory?.id) {
      getSitesDataList(getHomeAllSiteList(selectedCategory?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory?.id]);

  useEffect(() => {
    const consolidatedKeysLength = Object.keys(consolidatedData)?.length;
    setResetKey(consolidatedKeysLength, consolidatedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidatedData]);

  useEffect(() => {
    if (prefectureApiLoading && !prefectureApiError && !prefectureApiData) {
      setLoading(true);
    } else if (
      prefectureApiData && !prefectureApiLoading &&
      !prefectureApiError
    ) {
      dispatch(updateAccordionList(prefectureApiData?.result));
      const consolidated = consolidateByState(prefectureApiData?.result);
      setConsolidatedData(consolidated);
      const consolidatedKeys = Object.keys(consolidated);
      if (consolidatedKeys?.length === 1) {
        setExpandedAccordion(consolidatedKeys[0]);
      } else {
        setExpandedAccordion(null);
      }
      setLoading(false)
    }
  }, [prefectureApiData, prefectureApiError, prefectureApiLoading, dispatch]);

  const handleAccordionChange = (state) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? state : null);
  };

  useEffect(() => {
    const updatedConsolidatedData = consolidateByState(data);
    setConsolidatedData(updatedConsolidatedData);
  }, [data]);

  const handleSearch = (searchTyped, searchQuerys) => {
    setSearchType(searchTyped)
    setSearchQuery(searchQuerys)
  };

  useEffect(() => {
    const updatedFilteredData = accordionList?.filter((item) => {
      if (searchType === "area") {
        const stateName = prefectures?.find(pref => pref?.value === item?.state)?.label?.toLowerCase();
        return stateName?.includes(searchQuery?.toLowerCase());
      }
      else if (searchType === "site") {
        return item?.siteName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      }
      else if (searchType === "address") {
        return item?.address1?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      }
      else return true;
    });
    setData(updatedFilteredData)
  }, [searchType, searchQuery, accordionList])

  const noSearchFunction = () => {
    return (
      <Stack className="noItemsContainer">
        <Typography>
          {noResultFound}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      {!prefectureApiData && !prefectureApiError && prefectureApiLoading ? (
        <CustomCircularProgress
          size={44}
          inputSx={landingPageStyle?.loaderStyle}
        />
      ) : (
        <Box className="landingPageParentBox">
          {siteListCount === "blank" ? (
            <>
              <Box className="outerCardContent">
                <ElectricityCard
                  totalElectricity={totalElectricity}
                  cards={cards}
                  noDataText={noDataText}
                />
                <AlarmCard cards={cards} totalAlarms={totalAlarms} noDataText={noDataText} />
              </Box>
              <LandingPageSearchBar resetKey={resetKey} areaCount={Object.keys(consolidatedData)?.length} onSearch={handleSearch} setSearchQuery={setSearchQuery} searchQuery={searchQuery} searchType={searchType} setSearchType={setSearchType} accordionHeader={accordionHeader}></LandingPageSearchBar>
              {data?.length > 0 ? Object.keys(consolidatedData)?.map((state) => {
                const stateData = data?.filter((site) => site?.state === state);
                return (
                  <Box key={state}>
                    <PrefectureAccordion
                      key={state}
                      stateName={getSingleStateName(state)}
                      cards={cards}
                      title={`Sites in ${getSingleStateName(state)}`}
                      data={stateData}
                      columns={siteListTableHeader}
                      expanded={expandedAccordion === state}
                      onChange={handleAccordionChange(state)}
                      selectedCategory={selectedCategory}
                      landingSearchQuery={searchQuery}
                    />
                  </Box>
                );
              }) :
                noSearchFunction()
              }
            </>
          ) : (
            siteListCount === 'empty' && (
              <NoPrefecture selectedCategory={selectedCategory} />
            )
          )}
        </Box >
      )
      }
    </>
  );
}
export default LandingPage;

