/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddSiteCard from "./AddSiteCard";
import CustomTabs from "../../common/tab-component/CustomTabs";
import "./setting.scss";
import "../langing-page/LandingPages.scss";
import NoCustomer from "./NoCustomer";
import AddCustomerForm from "./AddCustomerForm";
import { getAllCustomersApi } from "../../redux/slices/customerSlice";
import { getAllCustomeWithPaginationEndPoint2 } from "../../services/apiConfig";
import { useDispatch } from "react-redux";
import CustomerAndSite from "./CustomerAndSite";
import NoSite from "./NoSite";
import { sortingKeys } from "./siteDetails/siteDetails.Data";
import { getRequestCustomerApi } from "../../common/utils/CommonUtils";
import { ApiPayload } from "./ApiAction";
import { useLocation } from "react-router-dom";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";

function SettingTabs({ user, setShowAddCustomer, showAddCustomer, type }) {
  const [activeTab, setActiveTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [customerList, setCustomerList] = useState("initial");
  const [screenType, setScreenType] = useState("initial");
  const [siteLength, setSiteLength] = useState(0);
  const [showEmptyPage, setShowEmptyPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation()
  const [custApiLoading, setCustApiLoading] = useState(false)
  const { t } = useTranslation();
  const { settingsPageContent } = t("mainContainer");
  const [currentFilter, setcurrentFilter] = useState(location?.state?.from === "addSiteButton" ? "A to Z" : "Newest on top");
  const { tabsContent } = settingsPageContent;

  const dispatch = useDispatch();

  const handleCustomTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const fetchCustomers = () => {
    let requestParams = getRequestCustomerApi({
      limit: 10,
      page: currentPage,
      sortBy: sortingKeys?.[currentFilter],
    });
    handlePageChange(requestParams);
  };

  const handlePageChange = (reqParams) => {
    setScreenType("initial")
    setCustApiLoading(true);
    dispatch(
      getAllCustomersApi(
        ApiPayload(getAllCustomeWithPaginationEndPoint2(`${reqParams}`))
      )
    ).then((res) => {
      if (res?.payload?.status == "200") {
        setCustomerList(res?.payload?.data?.results);
        setScreenType(res?.payload?.data?.results?.length > 0 ? "customerList" : "noCustomer");
      }
      setCustApiLoading(false);
    }).catch(() => {
      setCustApiLoading(false);
    });

  };

  useEffect(() => {
    if (!showAddCustomer) {
      fetchCustomers();
    }
  }, []);

  useEffect(() => {
    if (showAddCustomer) {
      setScreenType("addCustomer");
      setShowEmptyPage(true);
    }

  }, [customerList, showAddCustomer]);

  const getScreenType = (screenType) => {
    switch (screenType) {
      case "initial":
        return (
          <CustomCircularProgress
            inputSx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
            size={44} ></CustomCircularProgress>
        )
      case "noCustomer":
        return (
          <NoCustomer
            setScreenType={setScreenType}
            setShowAddCustomer={setShowAddCustomer}
            customerList={customerList}
          />
        );
      case "customerList":
        return (
          <CustomerAndSite
            customerList={customerList}
            isEdit={isEdit}
            screenType={screenType}
            setScreenType={setScreenType}
            setIsEdit={setIsEdit}
            setEditableData={setEditableData}
            showAddCustomer={showAddCustomer}
            setShowAddCustomer={setShowAddCustomer}
            type={type}
            setCustomerList={setCustomerList}
            setSiteLength={setSiteLength}
            siteLength={siteLength}
            setShowEmptyPage={setShowEmptyPage}
            showEmptyPage={showEmptyPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            currentFilter={currentFilter}
            setcurrentFilter={setcurrentFilter}
            custApiLoading={custApiLoading}
            setCustApiLoading={setCustApiLoading}
          />
        );
      case "addCustomer":
        return (
          <Box
            style={{
              display: "flex",
              height: "calc(100%)",
            }}
          >
            <AddCustomerForm
              user={user}
              customerList={customerList}
              setScreenType={setScreenType}
              isEdit={isEdit}
              editableData={editableData}
              setIsEdit={setIsEdit}
              setCustomerList={setCustomerList}
              setShowAddCustomer={setShowAddCustomer}
              showEmptyPage={showEmptyPage}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
            />
            <div
              style={{
                width: "100%",
                marginLeft: "0px",
              }}
            >
              <NoSite
                screenType={screenType}
                showEmptyPage={showEmptyPage}
              ></NoSite>
            </div>
          </Box>
        );
      default:
        return null;
    }
  };
  // const tabData = [
  //   {
  //     label: "Customer and site management",
  //     content: getScreenType(screenType),
  //   },
  //   {
  //     label: "User and permissions management",
  //     content: <AddSiteCard />,
  //   },
  // ];

  const tabData = tabsContent?.map((tabName, index) => {
    return {
      label: tabName,
      content:
        tabName === tabsContent[0] ? (
          getScreenType(screenType)
        ) : (
          <AddSiteCard />
        ),
      key: index,
    };
  });
  const tabStyles = {
    xl: 6.5,
    tabs: {
      border: "1px solid #0F62FE",
      borderRadius: "4px",
    },
    tab: {
      flex: 1,
      textTransform: "none",
      minWidth: 0,
      padding: "10px 15px",
      linHeight: "20px",
      backgroundColor: "#ffffff",
      color: "#000",
      "&.Mui-selected": {
        backgroundColor: "#0F62FE",
        color: "#fff",
      },
    },
  };

  return (
    <Grid
      item
      lg={12}
      p={2}
      height={"100%"}
      gap={2}
      xl={12}
      className="settingTabWidth"
    >
      <CustomTabs
        tabData={tabData}
        activeTab={activeTab}
        onTabChange={handleCustomTabChange}
        tabStyles={tabStyles}
        tabContentWidthLg={12}
        tabContentWidthMd={8}
        mainGridWidth={5.29}
      />
    </Grid>
  );
}
export default SettingTabs;
