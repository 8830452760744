// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[0].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[4]!../../../node_modules/rsuite/dist/rsuite.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-anim-fade.rs-anim-in {
  z-index: 1111;
}

.rs-stack-item .rs-stack-item .active-shortcut {
  background-color: #007bff;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/top-common-navigation/DateRangeCalendar.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,WAAA;AAAJ","sourcesContent":["@import \"rsuite/dist/rsuite.css\";\n\n.rs-anim-fade.rs-anim-in { \n    z-index: 1111;\n}\n\n.rs-stack-item .rs-stack-item .active-shortcut {\n    background-color: #007bff; // Example active color\n    color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
