import {
  Typography,
  Card,
} from "@mui/material";
import React from "react";
import { ReactComponent as BsLightning } from "../../assets/images/Lightening.svg";
import { ReactComponent as Chart } from "../../assets/images/ChartColumn.svg";
function ElectricityCard({ totalElectricity,cards }) {
  return (
      <Card className="electricityAlarmBox">
        <div className="cardBox">
          <Typography variant="span"> {cards?.electricityTitle} </Typography>
          <Chart />
        </div>
        <div className="innerContent">
          <span className="electricitySpaned">{<BsLightning />}</span>
          <Typography className="electricityText">
            {totalElectricity}
          </Typography>
          <span className="electricitySpan">{cards?.electricityUnit}</span>
        </div>
      </Card>
  );
}

export default ElectricityCard;
