import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from "lottie-react";
import { Alert, Stack, Typography, Button, Box, Checkbox, IconButton, TextField, InputAdornment, FormControlLabel } from '@mui/material';
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import spinner from "../../../../../../assets/animation/spinner.json";
import { ReactComponent as DangerIcon } from "../../../../../../assets/images/danger.svg";
import { ReactComponent as HomeIcon } from "../../../../../../assets/images/homeIcon.svg";
import { ReactComponent as AlarmIcon } from "../../../../../../assets/images/alarmIcon.svg";
import { ReactComponent as HomeStartIcon } from "../../../../../../assets/images/homeStartIcon.svg";
import { ReactComponent as AlarmStartIcon } from "../../../../../../assets/images/alarmStartIcon.svg";
import { ReactComponent as CircleFill } from "../../../../../../assets/images/Circle-fill.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/images/deleteIcon.svg";
import { ReactComponent as AccorDown } from "../../../../../../assets/images/accorDown.svg";
import { ReactComponent as AccorUp } from "../../../../../../assets/images/accorUp.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/edit.svg";
import CustomerFilterPanel from '../../../../../../common/customerFilterPanel/CustomerFilterPanel';
import useFetch from '../../../../../../common/hook/useFetch';
import { getHomeAllSiteList, getSavedFavorite, deleteFavoriteURl, saveFaorite,getRecentSearch } from '../../../../../../services/apiConfig';
import { setUpdateRecentSearchId } from '../../../../../../redux/slices/customerFilterSlice';
import usePatch from '../../../../../../common/hook/usePatch';
import useDelete from '../../../../../../common/hook/useDelete';
import usePost from '../../../../../../common/hook/usePost';
import { deleteFavoriteItem, setDefaultDelete, setMyFavoriteLists ,setMyRecentSearchList,setSelectedAreas,setSelectedSites,setSelectedCategory,setUpdateBeadcrumbs} from '../../../../../../redux/slices/customerFilterSlice';
import { ReactComponent as FavBlueStar } from "../../../../../../assets/images/FavBlueStar.svg";
import { prefectures } from '../../../../../setting/CustomerData';
import { customerTabStyles } from '../allCustomerTab/AllCustomerTabStyle'; 
import { recentSearchURL } from '../../../../../../services/apiConfig';
import { setRecentSearchId } from '../../../../../../redux/slices/customerFilterSlice';
import './FavoritesContent.scss';

// Function to group sites by state
const groupSitesByState = (sites) => {
    return sites.reduce((acc, site) => {
        if (!acc[site.state]) {
            acc[site.state] = [];
        }
        acc[site.state].push(site);
        return acc;
    }, {});
};




const FavoritesContent = ({ searchType, searchQuery,handleCancel }) => {
    const [expanded, setExpanded] = React.useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState('allFavorites');
    const [activecustomerId, setActiveCustomerId] = useState();
    const [customerId, setCustomerID] = useState();
    const [activeAreas, setActiveAreas] = useState();
    const [editMode, setEditMode] = useState(null); // Track which item is in edit mode
    const [deleteMode, setDeleteMode] = useState(null); // Track which item is in edit mode
    const [editFavoriteNames, setEditFavoriteNames] = useState({}); // Track favorite names for each item
    const [noAreaSelected, setNoAreaSelected] = useState(false)
    const [noSiteSelected, setNoSiteSelected] = useState(false)
    const[deletingFav,setDeletingFav]=useState(false) 

    const [favError, setFavError] = useState({}); // To manage field errors
    const [isValidCheckbox, SetIsValidCheckbox] = useState('')

    const [favoriteData, updateFavoriteData] = useState();
    const dispatch = useDispatch();
    // Local state to track checkbox values for each item in edit mode
    const [editCheckboxState, setEditCheckboxState] = useState({});

    // Language Parameter
    const { t } = useTranslation();
    const { customerTopNavigation } = t("mainContainer");
    const { actions, favDeleteLoaing,favoriteTabsText, defaultLabel, favCheckBoxes, noFavMessage, areaErrorMessage, siteErrorMessage, homeAlarmCheckBoxError, favNameErrorMessage ,favNameDupErrorMessage} = customerTopNavigation;


    const { loading, error, data, patch } = usePatch(saveFaorite);
    const { loading: deleteFavLoading, error: deleteFavError, data: deleteFavData, deleteRequest } = useDelete(deleteFavoriteURl);
    const [deletingItemId, setDeletingItemId] = useState(null);
    const navigate = useNavigate();
    // Group the sites by their state
    const [sites, setSites] = useState([])
    // Ref to track if cancel edit was triggered
    const cancelEditRef = useRef(false);

    const userId = localStorage.getItem("userId");

    // Group the area by their state
    const [areas, setAreas] = useState([])
    const { myFavoriteLists, myRecentSearchList,recentSearchId,isUpdateRecentSearchId } = useSelector(state => state.customer);
    const {
        data: recentData,
        error: recentError,
        loading: recentLoading,
        fetchData: getRecentData,
    } = useFetch();
    const {
        data: fetchsitesData,
        error: fetchsiteError,
        loading: fetchsiteErrorLoading,
        fetchData: getSitesDataList,
    } = useFetch();
    const {
        data: savedFavorite,
        error: savedFavoriteError,
        loading: savedFavoriteLoading,
        fetchData: getAllSavedFavorite,
    } = useFetch();
    const {
        loading: recenSearchLoading,
        error: recenSearchError,
        data: recenSearchData,
        postData: doPostrecetSearch,
      } = usePost();

      const [hasDuplicate, sethasDuplicate]=useState(false)
    useEffect(() => {
        if (savedFavorite && !savedFavoriteError && !savedFavoriteLoading) {
            dispatch(setMyFavoriteLists(savedFavorite));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFavorite, savedFavoriteError, savedFavoriteLoading])

    useEffect(() => {
        if (recenSearchData && !recenSearchLoading && !recenSearchError) {
            // if(recenSearchData?.result?.id === recentSearchId){
            //     dispatch(setUpdateRecentSearchId(!isUpdateRecentSearchId))
            // } 
            dispatch(setRecentSearchId(recenSearchData?.result?.id)); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recenSearchData, recenSearchLoading, recenSearchError])


    useEffect(() => {
        if (data && !error && !loading) {
            getAllSavedFavorite(getSavedFavorite(userId))
            setEditMode(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data])

    useEffect(() => {
        if (!data && error && !loading) {
          sethasDuplicate(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data])

    useEffect(()=>{
        if(deleteFavData&&!deleteFavError&&!deleteFavLoading&&recentData&&!recentError&&!recentLoading){
            getAllSavedFavorite(getSavedFavorite(userId))
            dispatch(setUpdateBeadcrumbs(true));
            
             // To check if there is any item with defaultFavourite true
            //  const hasDefaultFavorite = myFavoriteLists?.some(item => item.defaultFavourite) || false;
                if(deletingFav&&recentData?.length>0){
                    const states = recentData[0]?.siteList?.map(item => prefectures.find(pref => pref.value === item.state)?.label);
                   const areas = [...new Set(states)];
                   const sites = recentData[0]?.siteList.map(item => ({
                   id: item.siteId,
                   state: prefectures.find(pref => pref.value === item.state)?.label,
                   name: item.siteName
                   }));
                   dispatch(setSelectedCategory({
                     id: recentData[0].customerId,
                     name: recentData[0].customerName,
                     displayName: recentData[0].customerName
                   }));
                   
                   dispatch(setSelectedAreas(areas));
                   dispatch(setSelectedSites(sites));
            
                    const recentSearchPayload = {
                        "userId": userId,
                        "customerId": recentData[0].customerId,
                        "customerName": recentData[0].customerName,
                        "siteList": recentData[0]?.siteList,
                        "lastUsed": new Date().getTime()
                    };
            
                    doPostrecetSearch(recentSearchURL, recentSearchPayload);
  
            }

        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ deleteFavLoading,deleteFavError,deleteFavData,recentData,recentLoading,recentError])

    useEffect(() => {
        if (fetchsitesData && fetchsitesData.length > 0 && !fetchsiteError && !fetchsiteErrorLoading) {
            // Group customerSites by state
            const groupedSites = groupSitesByState(fetchsitesData);
            setSites(groupedSites);
            const groupedArea = Object.keys(groupedSites).map(state => ({
                state: state,
                id: activecustomerId
            }));
        } else if (fetchsitesData.length === 0) {
            setSites([]);  // or any other appropriate action for empty data
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchsitesData, fetchsiteError, fetchsiteErrorLoading]);

    useEffect(() => {
        // && Object.keys(sites).length > 0
        if (fetchsitesData && fetchsitesData.length > 0 && !fetchsiteError && !fetchsiteErrorLoading) {
            setAreas(Object.keys(sites).sort((a, b) => a.localeCompare(b)));
        } else if (fetchsitesData.length === 0) {
            setAreas([]);  // or any other appropriate action for empty data
        }
    }, [fetchsitesData, fetchsiteError, fetchsiteErrorLoading, sites])

    const noSearchFunction = () => {
        return (
            <Stack
                sx={customerTabStyles.noRecentSearch}
            >
                <Typography sx={customerTabStyles.infoTitle}>
                    No search results found!
                </Typography>
            </Stack>
        );
    };

    useEffect(() => {
        if (expanded !== false) {
            setActiveCustomerId(customerId?.id);
            getSitesDataList(getHomeAllSiteList(customerId?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, setCustomerID])

    useEffect(() => {
        if (expanded !== false) {
            const { home, alarm } = editCheckboxState;
            if (!home && !alarm) {
                SetIsValidCheckbox(homeAlarmCheckBoxError);
                return;
            }
            // Clear error if validation passes
            SetIsValidCheckbox('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCheckboxState])

    const handleChange = (panel, item) => (event, isExpanded) => { 
        if (cancelEditRef.current) {
            cancelEditRef.current = false; // Reset the flag after checking
            return; // Skip the rest of the handler if cancel edit was triggered
        }
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            getSitesDataList(getHomeAllSiteList(item?.customerId));
            setActiveCustomerId(item?.customerId);

            const groupedSites = groupSitesByState(item?.siteList);
            setSites(groupedSites);
            const groupedArea = Object.keys(groupedSites).map(state => ({
                state: state,
                id: item?.customerId
            }));
            setActiveAreas(groupedArea);
        }
    };
    const handleExpandClick = (index,item) => {
        setExpanded(expanded === index ? null : index);
        if (expanded!==index) {
            getSitesDataList(getHomeAllSiteList(item?.customerId));
            setActiveCustomerId(item?.customerId);

            const groupedSites = groupSitesByState(item?.siteList);
            setSites(groupedSites);
            const groupedArea = Object.keys(groupedSites).map(state => ({
                state: state,
                id: item?.customerId
            }));
            setActiveAreas(groupedArea);
        }
    };



    const filteredFavoritesDatum = myFavoriteLists?.filter((item) => {
        if (searchType === "favorite") {
            return item?.favouriteName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
        } else if (searchType === "customer") {
            return item?.customerName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
        } else if (searchType === "area") {
            return item?.siteList?.some(site => {
                const stateName = prefectures?.find(pref => pref?.value === site?.state)?.label?.toLowerCase();
                return stateName?.includes(searchQuery?.toLowerCase());
            });
        } else if (searchType === "site") {
            return item?.siteList?.some(site =>
                site?.siteName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            );
        } else return true;
    });

    // const myFavoriteLists = [];
    // Filter favorites based on selected category
    const filterFavorites = (type) => {
        if (type === 'allFavorites') {
            return filteredFavoritesDatum;
        } else {
            return filteredFavoritesDatum.filter(item => item.favouriteType.includes(type));
        }

    };

    const favoritesToDisplay = filterFavorites(selectedCategoryName);



    if (myFavoriteLists?.length == 0) {
        return <Box className="no-favorite" direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <Typography className="title">
                {noFavMessage["title"]}
            </Typography>
            <Typography className="info">
                {noFavMessage["info"]}
            </Typography>
        </Box>
    }


    const handleEditClick = (itemId, index,item) => {
        setEditMode(itemId);

        if (expanded === index) {
            cancelEditRef.current = true; // Set the flag to skip the next handleChange call
        }

        if (expanded!==index) {
            getSitesDataList(getHomeAllSiteList(item?.customerId));
            setActiveCustomerId(item?.customerId);

            const groupedSites = groupSitesByState(item?.siteList);
            setSites(groupedSites);
            const groupedArea = Object.keys(groupedSites).map(state => ({
                state: state,
                id: item?.customerId
            }));
            setActiveAreas(groupedArea);
        }
        
            setExpanded(index); // Ensure the panel is expanded if no panel is currently expanded
        

        // Initialize checkbox state for the item being edited
        const itemToEdit = filteredFavoritesDatum.find(item => item.id === itemId);
        setEditCheckboxState({
            home: itemToEdit.favouriteType.includes('home'),
            alarm: itemToEdit.favouriteType.includes('alarm'),
            default: itemToEdit.defaultFavourite
        });
        setEditFavoriteNames(prevState => ({
            ...prevState,
            [itemId]: itemToEdit.favouriteName
        }));

        
    };

    const handleCancelEdit = () => {
        cancelEditRef.current = true; // Set the flag to skip the next handleChange call

        setEditMode(null);
        return;
    };

    const handleSaveEdit = async (item) => {

        const { selectedCustomer, selectedSites } = favoriteData;
        const payload = {
            id: item.id,
            userId: item.userId,
            customerId: selectedCustomer?.id,
            customerName: selectedCustomer?.name,
            favouriteName: editFavoriteNames[item.id],  // Use the favouriteName state here
            siteList: selectedSites?.map(site => ({
                "siteId": site.id,
                "state": site.state,
                "siteName": site.name
            })),
            favouriteType: [
                editCheckboxState.home ? 'home' : null,
                editCheckboxState.alarm ? 'alarm' : null
            ].filter(Boolean),
            defaultFavourite: editCheckboxState.default,
            isFavourite: true,
            lastUsed: new Date().getTime()
        };
        if (editFavoriteNames[item.id] === '') {
            cancelEditRef.current = true; // Set the flag to skip the next handleChange call
            return
        } 
        // Implement save logic here
        cancelEditRef.current = true; // Set the flag to skip the next handleChange call
        // setEditMode(null);
        // Optionally, you can keep the expanded panel open or collapse it

        try {
            if (!favError[item.id] && isValidCheckbox === '')
                await patch(payload);
            if(item.id === recentSearchId){
                dispatch(setUpdateRecentSearchId(!isUpdateRecentSearchId))
            } 
if(item.id === recentSearchId)
          { 
            
            dispatch(setSelectedCategory({
                id: selectedCustomer?.id,
                name: selectedCustomer?.name,
                displayName: editFavoriteNames[item.id]
                }));  

                const states = payload?.siteList?.map(item => prefectures.find(pref => pref.value === item.state)?.label);
                const areas = [...new Set(states)];
        
                dispatch(setSelectedAreas(areas)); 
        
                const sites = payload?.siteList.map(item => ({
                    id: item.siteId,
                    state: prefectures.find(pref => pref.value === item.state)?.label,
                    name: item.siteName
                    }));
                dispatch(setSelectedSites(sites));
        
                const recentSearchPayload = {
                    "userId": userId,
                    "customerId": selectedCustomer?.id,
                    "customerName": selectedCustomer?.name,
                    "siteList": selectedSites?.map(site => ({
                        "siteId": site.id,
                        "state": site.state,
                        "siteName": site.name
                    })),
                    "lastUsed": new Date().getTime()
                  };
          
                  doPostrecetSearch(recentSearchURL, recentSearchPayload);
                  }
        } catch (err) {
            console.error('Save failed:', err);
        }

    };

    const handleDeleteClick = async (itemId, item,idx) => {
        setDeletingItemId(item.id);
        cancelEditRef.current = true; // Set the flag to skip the next handleChange call
        
        // console.log(myRecentSearchList)
        // console.log(item)
        // dispatch(deleteFavoriteItem(itemId));
         
        if(item?.defaultFavourite){
            setDeletingFav(true)
        }else{
            setDeletingFav(false)
        }
         dispatch(setUpdateBeadcrumbs(false));
        // Implement delete logic here 
        await deleteRequest({ id: itemId })
        await getRecentData(getRecentSearch(userId));
        if (item.defaultFavourite) {
            dispatch(setDefaultDelete(true)); 
        }
       
    };

    const handleCheckboxChange = (type) => (event) => {
        setEditCheckboxState({
            ...editCheckboxState,
            [type]: event.target.checked
        });

    };


    const checkForDuplicate = (name, excludeId) => {
        const filteredFavorites = favoritesToDisplay?.filter(item => item.id !== excludeId);
        return filteredFavorites.some(item => item.favouriteName === name);
    };


    const handleFavoriteNameChange = (event, itemId) => {
        sethasDuplicate(false)
        setEditFavoriteNames(prevState => ({
            ...prevState,
            [itemId]: event.target.value
        }));
        // Validation
        if (!event.target.value.trim()) {
            setFavError(prev => ({ ...prev, [`${itemId}-req`]: 'Required field must be filled in.' }));
            return; // Prevent saving if validation fails
        }
        if (checkForDuplicate(event.target.value, itemId)) {
            sethasDuplicate(true);
            setFavError(prev => ({ ...prev, [`${itemId}-dup`]: 'Duplicate name is not allowed.' }));
            return;
        }
       // Clear existing errors for this item
        setFavError(prev => ({
            ...prev,
            [`${itemId}-req`]: '',
            [`${itemId}-dup`]: ''
        }));

    };
const handleSpinner = (id)=>{
    if(deletingItemId===id)
  {  
    return <>   
    <Lottie className="spinner-blue" animationData={spinner} autoplay loop />
    <span className="delete-label">{favDeleteLoaing}</span>
    </>;

  }else{
    return '';
  }

}

const handleRedirect = (item) => {
    // Check if favouriteType only contains 'alarm'
    if (item.favouriteType.length === 1 && item.favouriteType.includes('alarm')) {
        // Redirect to alarm page
        navigate('/alarm'); // Update with your actual alarm path
         
        dispatch(setSelectedCategory({
            id: item.customerId,
            name: item.favouriteName,
            displayName: item.favouriteName
          })); 

          const states = item?.siteList?.map(item => prefectures.find(pref => pref.value === item.state)?.label);
          const areas = [...new Set(states)];

          dispatch(setSelectedAreas(areas)); 

          const sites = item?.siteList.map(item => ({
            id: item.siteId,
            state: prefectures.find(pref => pref.value === item.state)?.label,
            name: item.siteName
            }));
          dispatch(setSelectedSites(sites));
          handleCancel()
    } else {
           
        dispatch(setSelectedCategory({
        id: item.customerId,
        name: item.customerName,
        displayName: item.favouriteName
        })); 
        const states = item?.siteList?.map(item => prefectures.find(pref => pref.value === item.state)?.label);
        const areas = [...new Set(states)];

        dispatch(setSelectedAreas(areas)); 

        const sites = item?.siteList.map(item => ({
            id: item.siteId,
            state: prefectures.find(pref => pref.value === item.state)?.label,
            name: item.siteName
            }));
        dispatch(setSelectedSites(sites));

        const recentSearchPayload = {
            "userId": userId,
            "customerId": item.customerId,
            "customerName": item.customerName,
            "siteList": item?.siteList,
            "lastUsed": new Date().getTime()
          };
  
          doPostrecetSearch(recentSearchURL, recentSearchPayload);

         // Redirect to alarm page
        navigate('/home'); // Update with your actual alarm path
        handleCancel()
    }
};

 
    return (
        <div className="favoriteContent">

            {/* Button Group for Filtering Favorites */}
            <Box className="myfavoriteActionButton" display="flex">
                <Button
                    variant='outlined'
                    className={selectedCategoryName === 'allFavorites' ? 'active' : ''}
                    onClick={() => setSelectedCategoryName('allFavorites')}
                >
                    {favoriteTabsText["tabOne"]}
                </Button>
                <Button
                    startIcon={<HomeStartIcon />}
                    variant='outlined'
                    className={selectedCategoryName === 'home' ? 'active' : ''}
                    onClick={() => setSelectedCategoryName('home')}
                >
                    {favoriteTabsText["tabTwo"]}
                </Button>
                <Button
                    startIcon={<AlarmStartIcon />}
                    variant='outlined'
                    className={selectedCategoryName === 'alarm' ? 'active' : ''}
                    onClick={() => setSelectedCategoryName('alarm')}
                >
                    {favoriteTabsText["tabThree"]}
                </Button>
            </Box>

            <Box className="accordion-container">
                {!!favoritesToDisplay?.length ? (favoritesToDisplay?.map((item, index) =>
                    <div className="accordion-content">

                        <Typography className="inputText-field-fav-name" sx={{ width: editFavoriteNames[item.id] === '' ? '50%' : '60%', flexShrink: 0 }}>
                            {
                                editMode === item.id && (
                                    <TextField
                                        onChange={(event) => handleFavoriteNameChange(event, item.id)}
                                        value={editFavoriteNames[item.id] || ''}
                                        id="outlined-basic"
                                        className="favoriteInputField"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: !!favError[item.id] && (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="error">
                                                        <ErrorIcon color="error" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!favError[`${item.id}-req`] ||!!favError[`${item.id}-dup`] } // Set error prop based on error state
                                        helperText={(!!favError[`${item.id}-req`] && favNameErrorMessage)||(!!favError[`${item.id}-dup`] && favNameDupErrorMessage)} // Show error message if exists
                                    />
                                )}
                        </Typography>
                        <Accordion expanded={expanded === index}>
                           
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <div style={{ width:`${editMode === item.id && "428px" }`,left:`${editMode === item.id && "223px" }`}}className="handleRedirectFunctionOverlay" onClick={()=>handleRedirect(item)}>handleRedirect function</div>
                                <Typography sx={{
                                    width: editFavoriteNames[item.id] === '' ? '' : '40%', flexShrink: 0, display: "flex", fontSize: "14px",
                                    justifyContent: "start", /* Center horizontally */
                                    alignItems: "center", /* Center vertically (if needed) */
                                }}>
                                    <IconButton sx={{ marginRight: "3px" }} aria-label="delete" size="small" >
                                        <FavBlueStar fontSize="inherit" />
                                    </IconButton>
                                    {editMode !== item.id && item.favouriteName}
                                </Typography>

                               { deletingItemId === item.id &&  <Typography className="fav-deletSpinner" sx={{
                                    color: 'text.secondary', display: "flex",
                                    justifyContent: "center", /* Center horizontally */
                                    alignItems: "center", /* Center vertically (if needed) */
                                    gap: "8px" /* Optional: Add some space between text and icons */
                                }}>
                                 {  
                                  handleSpinner(item.id)
                                 } 

                                </Typography>}
                                { !(!!favError[`${item.id}-req`] ||!!favError[`${item.id}-dup`]) && <Typography className="fav-actions" sx={{
                                    color: 'text.secondary', display: "flex",
                                    justifyContent: "center", /* Center horizontally */
                                    alignItems: "center", /* Center vertically (if needed) */
                                    gap: "8px" /* Optional: Add some space between text and icons */
                                }}>
                                   
                                    <span className="default-label" style={{visibility:`${item.defaultFavourite?"visible":"hidden"}`}}> <CircleFill /> {defaultLabel}</span>
                                    {item.favouriteType.includes('home') && <HomeIcon sx={{ marginRight: 1 }} />}
                                    {item.favouriteType.includes('alarm') && <AlarmIcon />}  

                                </Typography>}
                                <Typography sx={{ marginLeft: "auto" ,lineHeight:"1.8"}}>
                                    {editMode !== item.id ? (
                                        <span className='fav-action'>
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(item.id, item,index)}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="edit" size="small" onClick={() => handleEditClick(item.id, index,item)}>
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                        </span>
                                    ) : (
                                        <>
                                            <Button variant="outlined" className="cancel-btn" size="small" onClick={handleCancelEdit}>{actions["cancel"]}</Button>
                                            <Button variant="contained" disabled={!!favError[`${item.id}-dup`]||!!favError[`${item.id}-req`] || isValidCheckbox||hasDuplicate||noAreaSelected || noSiteSelected} className="save-btn" size="small" onClick={() => handleSaveEdit(item)}>{actions["save"]}</Button>
                                        </>
                                    )}
                                </Typography>
                               
                                <IconButton
                                    aria-label="expand"
                                    onClick={() => handleExpandClick(index,item)}
                                >
                                    {expanded === index ? <AccorUp /> : <AccorDown />}
                                </IconButton>
                                {/* <IconButton aria-label="delete" size="small">
                                    <AccorDown fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="delete" size="small">
                                    <AccorUp fontSize="inherit" />
                                </IconButton> */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {/* Expandable Content */}

                                    {expanded === index && <CustomerFilterPanel
                                        setNoSiteSelected={setNoSiteSelected}
                                        setNoAreaSelected={setNoAreaSelected}
                                        isFetchSiteLoading={!fetchsitesData && !fetchsiteError && fetchsiteErrorLoading}
                                        hasnoSiteData={fetchsitesData?.length == 0}
                                        setCustomerID={setCustomerID}
                                        updateFavoriteData={updateFavoriteData}
                                        activeCustomer={{ id: item.customerId, name: item.customerName }}

                                        activeSites={item.siteList} activeAreas={activeAreas} areas={areas} sites={sites} isEdit={editMode === item.id} />}
                                    {editMode === item.id && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', padding: "10px 16px", fontSize: "14px" }}>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size='small'
                                                            checked={editCheckboxState.home}
                                                            onChange={handleCheckboxChange('home')}
                                                        />
                                                    }
                                                    label={favCheckBoxes["home"]}
                                                />

                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size='small'
                                                            checked={editCheckboxState.alarm}
                                                            onChange={handleCheckboxChange('alarm')}
                                                        />
                                                    }
                                                    label={favCheckBoxes["alarm"]}
                                                />

                                            </Box>
                                            {isValidCheckbox && (
                                                <Stack sx={{ mr: "auto", alignSelf: "center !important" }}>
                                                    <Alert
                                                        className='checkbox-alert'
                                                        sx={{
                                                            marginLeft: "10px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            p: 0,
                                                            fontSize: "12px",
                                                            color: "#E34935",
                                                        }}
                                                        icon={<DangerIcon fontSize="inherit" />}
                                                        severity="plain"
                                                    >
                                                        {isValidCheckbox}
                                                    </Alert>
                                                </Stack>
                                            )}
                                            <Box sx={{ marginLeft: "auto" }}>
                                                <Checkbox
                                                    size='small'
                                                    checked={editCheckboxState.default}
                                                    onChange={handleCheckboxChange('default')}
                                                />
                                                {favCheckBoxes["default"]}
                                            </Box>

                                        </Box>

                                    )}

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )) : (noSearchFunction())}
            </Box>

            {(noAreaSelected || noSiteSelected) && editMode!==null && <Stack sx={{ mr: "auto", alignSelf: "center !important" }}>
                <Alert
                    className='fav-alert'
                    sx={{
                        marginTop: "6px",
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        p: 0,
                        fontSize: "12px",
                        color: "#E34935",
                    }}
                    icon={<DangerIcon fontSize="inherit" />}
                    severity="plain"

                >

                    {noAreaSelected && areaErrorMessage}
                    {!noAreaSelected && noSiteSelected && siteErrorMessage}
                </Alert>


            </Stack>}
        </div>
    );
}

export default FavoritesContent;
