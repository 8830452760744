// declare all your apis here
const API_BASE_URL = `${process.env.REACT_APP_NWC_BASE_URL}:${process.env.REACT_APP_NWC_PORT}/v1`;
export const registerEndPoint = `${API_BASE_URL}/auth/register`;
export const loginEndPoint = `${API_BASE_URL}/auth/login`;
export const logoutEndPoint = `${API_BASE_URL}/auth/logout`;
export const refreshTokensEndPoint = `${API_BASE_URL}/auth/refresh-tokens`;
export const forgotPasswordEndPoint = `${API_BASE_URL}/auth/forgot-password`;
export const resetPasswordEndPoint = `${API_BASE_URL}/auth/reset-password?token=`;
export const sendVerificationEmailEndPoint = `${API_BASE_URL}/auth/send-verification-email`;
export const verifyEmailEndPoint = `${API_BASE_URL}/auth/verify-email?token=`;
export const addCustomerEndPoint = `${API_BASE_URL}/customer`;
export const updateCustomerEndpoint = `${API_BASE_URL}/customer`;
export const changeLanguage = `${API_BASE_URL}/auth/change-language`;

// Initial endpoint with default values for limit and page
export const getAllCustomersEndpoint = `${API_BASE_URL}/customer`;
// export const getAllCustomeWithPaginationDefaultEndPoint = `${API_BASE_URL}/customer?limit=10&page=1`;
// Function to generate the getAllCustomeWithPagination endpoint dynamically
export const getAllCustomeWithPaginationEndPoint = (limit, page) => {
  return `${API_BASE_URL}/customer?limit=${limit}&page=${page}`;
};
export const customerSearchEndPoint = (name, limit, page) => {
  return `${API_BASE_URL}/customer?name=${name}&limit=${limit}&page=${page}`;
};
export const getAllSiteEndpoint = (limit, page, customerId) => {
  return `${API_BASE_URL}/site/?limit=${limit}&page=${page}&customerId=${customerId}`;
};
export const getAllSiteEndpointWithCustomerId = (customerId) => {
  return `${API_BASE_URL}/site?customerId=${customerId}`;
};
export const getSiteImage = `${API_BASE_URL}/site/get-file`;

export const siteEndPoint = `${API_BASE_URL}/site`;
export const getSingleSiteEndPoint = `${API_BASE_URL}/site`;

export const testConnectionURL = `${API_BASE_URL}/site/test-connection`;
export const getAlaramTagsURL = `${API_BASE_URL}/alarm-tags`;
export const createRuleURL = `${API_BASE_URL}/alarm-tags/create-rule`;
export const nameMockupApi = `${API_BASE_URL}/site/site-name-lookup`;
export const siteImageUploadURL = `${API_BASE_URL}/site/upload-file`;
export const siteImageDeleteURL = `${API_BASE_URL}/site/delete-file`;
export const customerListURL = `${API_BASE_URL}/home/customer-list`;
export const recentSearchURL = `${API_BASE_URL}/home/recent-search`;
export const saveFaorite = `${API_BASE_URL}/home/recent-search`;
export const deleteFavoriteURl = `${API_BASE_URL}/home/recent-search`;
export const electricityConsumptionURL = `${API_BASE_URL}/home/electricity-consumption`;

export const getHomeAllSiteList = (customerId) => {
  return `${API_BASE_URL}/home/site-list/${customerId}`;
};

export const getAllCustomeWithPaginationEndPoint2 = (parameterKeys) => {
  return `${API_BASE_URL}/customer${parameterKeys ? `?${parameterKeys}` : ""}`;
};

export const getAllSiteImagesURl = (customerId) => {
  return `${API_BASE_URL}/site/${customerId}/images`;
};

export const getSiteWithPaginationEndPoint = (parameterKeys) => {
  return `${API_BASE_URL}/site${parameterKeys ? `?${parameterKeys}` : ""}`;
};

export const getRecentSearch = (userId) => {
  return `${API_BASE_URL}/home/recent-search/${userId}`;
};

export const getSavedFavorite = (userId) => {
  return `${API_BASE_URL}/home/list-favourite/${userId}`;
};
