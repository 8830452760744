import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import CustomerNavigationDropdowns from './CustomerNavigationDropdowns';
import { leftNavStyles } from './LeftNavigationsStyles';
import { ReactComponent as Marker } from "../../../assets/images/Location.svg";



const CustomerLeftNavigation = () => {

  const [breadcrumbData, setbreadcrumbData] = useState(); 

  const { selectedCategory, selectedSites, selectedAreas, updateBeadcrumbs } = useSelector(state => state.customer);
  const areas = breadcrumbData?.selectedAreas?.join(', ');
  const sites = breadcrumbData?.selectedSites
    ?.map(site => site.name) // Extract names from the site objects
    .join(', '); // Join names with comma


  useEffect(() => {
    if (updateBeadcrumbs&&Object.keys(selectedCategory).length > 0 && selectedSites.length > 0 && selectedAreas.length > 0) {
      setbreadcrumbData({
        selectedCategory: selectedCategory,
        selectedSites: selectedSites,
        selectedAreas: selectedAreas
      }); 
    } else if (updateBeadcrumbs&&Object.keys(selectedCategory).length > 0 && selectedSites.length == 0){ 
        setbreadcrumbData({
          selectedCategory: selectedCategory,
          selectedSites: selectedSites,
          selectedAreas: selectedAreas
        });  
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBeadcrumbs,  selectedSites, selectedCategory, selectedAreas]);

  return (
    <React.Fragment>
      <Box 
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={leftNavStyles.box}
      >
        <Breadcrumb primaryLink={breadcrumbData?.selectedCategory?.name} secodaryLink={areas} activeLink={sites}/>
        <CustomerNavigationDropdowns setbreadcrumbData={setbreadcrumbData} breadcrumbData={breadcrumbData}  />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={leftNavStyles.marker} >
        <Marker />
      </Box>
    </React.Fragment>)
}

export default CustomerLeftNavigation;
