import { prefectures } from "../../components/setting/CustomerData";
export const isValidEmailAddress = (value) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(value);
};
export const hasStrongPassword = (value) => {
  const regex = /^(?=.*[0-9])(.*[a-zA-Z]){7,}.*$/;
  return regex.test(value);
};

export const isAlphaNumeric = (value) => {
  return /^[\p{L}\p{N}\s]*$/u.test(value);
};

export const isNumeric = (value) => {
  return /^[0-9]+$/.test(value);
};
// export const isValidJapanesePostalCode = (value) => {
//   return /^\d{3}-\d{4}$/.test(value);
// };

export const isValidJapanesePostalCode = (value) => {
  // Implement your logic to validate Japanese postal code format
  const regex = /^\d{3}-\d{4}$/;
  return regex.test(value);
};

export const isNumericWithHyphens = (value) => {
  return /^[0-9-]+$/.test(value);
};
// export const isValidJapanesePhoneNumber = (value) => {
//   return /^(\(0\d{1,3}\)|0\d{1,3})-\d{1,4}-\d{4}$/.test(value);
// };
export const isValidJapanesePhoneNumber = (value) => {
  // Remove hyphens to validate only the numbers
  const digits = value?.replace(/-/g, "");
  // Check if the number starts with a valid Japanese prefix and has the correct length
  return /^0\d{9,10}$/.test(digits);
};

export const isEmpty = (value) => {
  return !value?.toString()?.trim();
};

export const isValidUrl = (value) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "(www\\.)?" + // www
      "(" +
      "([a-zA-Z\\d-]+\\.)+" + // domain name prefix (optional)
      "(" +
      "[a-zA-Z\\d-]{2,}" + // domain name
      "|" +
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}" + // IP address
      ")" +
      ")" +
      "(\\:\\d{1,5})?" + // port (optional)
      "(\\/[-a-zA-Z\\d%_.~+]*)*" + // path (optional)
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string (optional)
      "(\\#[-a-zA-Z\\d_]*)?$", // fragment locator (optional)
    "i"
  );
  return pattern.test(value);
};

export const getRequestCustomerApi = (
  objKeys = { name: "", limit: 10, page: 1 }
) => {
  let finalText = "";
  Object.keys(objKeys).forEach((key1) => {
    if (objKeys?.[key1]) {
      finalText =
        finalText + `${finalText ? "&" : ""}${key1}=${objKeys?.[key1]}`;
    }
  });
  return finalText;
};

export const setSiteApiRequest = (siteObj, resData) => {
  let finalObj = {};
  Object.keys(siteObj).forEach((key) => {
    let tmpObj = siteObj[key];
    Object.keys(siteObj?.[key])?.forEach((v1) => {
      tmpObj = finalObj[key];
      finalObj = { ...finalObj, [key]: { ...tmpObj, [v1]: resData?.[v1] } };
    });
  });
  return finalObj;
};
export const formatDate = (dateString) => {
  // Check if dateString is undefined, null, or an empty string
  if (!dateString) {
    return ""; // Return empty string if dateString is invalid
  }
  const date = new Date(dateString);
  // Check if date is invalid
  if (isNaN(date?.getTime())) {
    console.error("Invalid date:", dateString);
    return ""; // Return empty string or handle error case
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

// function to get stateName by state code

export const getStateLabelForListing = (stateCode) => {
  const prefecture = prefectures?.find((pref) => pref?.value === stateCode);
  return prefecture ? prefecture?.label : stateCode;
};

// function to convert single state code to state name
export const getSingleStateName = (code) => {
  const prefectureObj = prefectures?.find((item) => item?.value === code);
  return prefectureObj ? prefectureObj?.label : "Unknown";
};

// function to convert single state code to state name
export const getSingleStateCode = (name) => {
  const prefectureObj = prefectures?.find((item) => item?.label === name);
  return prefectureObj ? prefectureObj?.value : "Unknown";
};
