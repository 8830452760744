import React, { useState, useRef, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  Divider,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as BellIcon } from "../../assets/images/SmallAlarm.svg";
import { ReactComponent as ChartIcon } from "../../assets/images/ChartColumn.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchIcon.svg";
import { homeSiteGridHeaderStructure } from "../setting/siteDetails/siteDetails.Data";
import { getStateLabelForListing } from "../../common/utils/CommonUtils";
import "./LandingPages.scss";
import SiteGrid from "./SiteGrid";
import { calculateTotals } from "./MockApi";

const PrefectureAccordion = ({
  title,
  data,
  expanded,
  onChange,
  stateName,
  selectedCategory,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchField, setShowSearchField] = useState(false);
  const [calculatedTotalData, setCalculatedTotalData] = useState({});
  const { t } = useTranslation();
  const {
    landingPage: { accordionHeader, searchPlaceholder, cards },
  } = t("mainContainer");
  const searchFieldRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target)
      ) {
        setSearchQuery("");
        setShowSearchField(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = searchQuery
    ? data?.filter((site) =>
        site?.siteName?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : data;

  useEffect(() => {
    const totalData = calculateTotals(data);
    setCalculatedTotalData(totalData);
  }, [data]);

  const transformApiResponse = (data) => {
    return data?.map((item) => ({
      ...item,
      state: getStateLabelForListing(item?.state),
    }));
  };

  const transformedData = transformApiResponse(filteredData);
  return (
    <Box>
      <Accordion
        className="accordionBox"
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="accordionSumChild">
            <div className="sumInnerChild">
              <Typography className="stateName">
                {title?.split(" ")[2]}
              </Typography>
              <Typography className="accordionSubHeaderStyle">
                {accordionHeader[0]}
                {calculatedTotalData?.totalSiteCount}
              </Typography>
            </div>
            <div className="sumInnerChild">
              <div className="electricity">
                <Typography className="accordionHeaderStyle">
                  {calculatedTotalData?.totalElectricity}
                  <span className="accordionSubHeaderStyle">
                    {cards?.electricityUnit}
                  </span>
                </Typography>
              </div>
              <Typography className="accordionSubHeaderStyle">
                {accordionHeader[1]}
              </Typography>
            </div>
            <div className="sumInnerChild">
              <Typography className="accordionHeaderStyle">
                {calculatedTotalData?.totalAlarmCount}
              </Typography>
              <Typography className="accordionSubHeaderStyle">
                {accordionHeader[2]}
              </Typography>
            </div>
            <div className="icon-hover">
              <div>
                <ChartIcon />
                <BellIcon />
              </div>
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails
          sx={{
            "&.MuiAccordionDetails-root": {
              padding: "8px 0px 16px 0px",
            },
          }}
        >
          {expanded && (
            <Stack className="prefectureAccDetail">
              <Typography className="stateText">
                {stateName} - {selectedCategory?.name}
              </Typography>
              {!showSearchField && (
                <IconButton onClick={() => setShowSearchField(true)}>
                  <SearchIcon />
                </IconButton>
              )}
              {showSearchField && (
                <TextField
                  size="small"
                  value={searchQuery}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={searchPlaceholder}
                  onChange={handleSearch}
                  className="searchWidth"
                  ref={searchFieldRef}
                  sx={{
                    "& .MuiInputBase-input": {
                      marginTop: "-10px",
                      marginBottom: "5px",
                    },
                    "& .MuiInputAdornment-root": {
                      marginTop: "-10px",
                      marginBottom: "15px",
                    },
                    "& .MuiFilledInput-root": {
                      position: "relative",
                      padding: "0 8px",
                    },
                  }}
                />
              )}
            </Stack>
          )}
          <SiteGrid
            // siteLength={filteredData.length}
            siteLength={filteredData.length}
            headerStructure={homeSiteGridHeaderStructure}
            // tableData={filteredData}
            tableData={transformedData}
            actionRequest={(action, rowVal) => {
              console.log(action, rowVal);
            }}
            sortOptions={["asc", "desc"]}
            tooltipColumns={[0, 1]}
            handleTableSorting={(order) => {
              console.log(order);
            }}
            siteListCount={filteredData.length}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PrefectureAccordion;
