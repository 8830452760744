import React, { useState } from 'react';
import { Container, Tabs, Tab, Box, Typography } from '@mui/material';
import AlarmSummary from './AlarmSummary';
import './AlarmHome.scss'

function AlarmHome() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return   <Box className="flex-container">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs example"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="Alarm summary" />
            <Tab label="Alarm log" /> 
          </Tabs>
          <Box sx={{ p: 0 ,width:"100%"}}>
            {value === 0 &&   <AlarmSummary/> }
            {value === 1 && <Typography>  Alarm Log</Typography>} 
          </Box>
        </Box> ;
}

export default AlarmHome;
