import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack
} from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import spinner from "../../../../../../assets/animation/spinner.json";
import { ReactComponent as ChevronRight } from "../../../../../../assets/images/RightArrow.svg";
import useFetch from "../../../../../../common/hook/useFetch";
import { getRecentSearch } from "../../../../../../services/apiConfig";
import { ReactComponent as FavBlueStar } from "../../../../../../assets/images/FavBlueStar.svg";
import { customerTabStyles } from "../allCustomerTab/AllCustomerTabStyle";
import { getSingleStateName, getStateLabelForListing } from "../../../../../../common/utils/CommonUtils";
import {
    setSelectedCategory,
    setSelectedAreas,
    setSelectedSites,
    setRecentSearchId,
    setUpdateBeadcrumbs,
} from "../../../../../../redux/slices/customerFilterSlice";
import { prefectures } from "../../../../../setting/CustomerData";
import { NWC_ALARM_URL } from "../../../../../../common/constants/Constants";

const RecentsTab = ({ handleClose, searchType = "customer", searchQuery = "" }) => {
    const {
        data: recentData,
        error: recentError,
        loading: recentLoading,
        fetchData: getRecentData,
    } = useFetch();
    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {
        landingPage: { recentTab }
    } = t("mainContainer");
    const [recentDatum, setRecentDatum] = useState([])
    const { myFavoriteLists, selectedCategory, selectedSites } = useSelector(state => state?.customer);

    useEffect(() => {
        if (userId) {
            getRecentData(getRecentSearch(userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Array.isArray(recentData)) {
            const validRecents = recentData?.filter(item => item?.siteList?.length > 0);
            setRecentDatum(validRecents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentData]);

    const noRecentFunction = () => {
        return (
            <Stack
                sx={customerTabStyles.noRecentSearch}
            >
                <Typography sx={customerTabStyles.infoTitle}>
                    {recentTab?.noSearchTitle}
                </Typography>
                <Typography sx={customerTabStyles.infoDesc}>
                    {recentTab?.noSearchSubTitle}
                </Typography>
            </Stack>
        );
    };

    const noRecentSearchFunction = () => {
        return (
            <Stack sx={customerTabStyles.noRecentSearch} >
                <Typography sx={customerTabStyles.infoTitle}>
                    {recentTab?.recentNoSearch}
                </Typography>
            </Stack>
        );
    };

    const handleItemSelect = (customer) => {

        dispatch(setUpdateBeadcrumbs(true));
        const alarmType = customer?.favouriteType?.length === 1 && customer?.favouriteType?.includes("alarm");
        const areas = [
            ...new Set(customer?.siteList?.map((site) => getStateLabelForListing(site?.state))),
        ];
        const transformApiResponse =
            customer?.siteList?.map((item) => ({
                id: item?.siteId,
                state: getStateLabelForListing(item?.state),
                name: item?.siteName
            }));
        dispatch(setSelectedSites(transformApiResponse))
        dispatch(setSelectedAreas(areas))
        const dropdownName = customer?.isFavourite && customer?.favouriteName ? customer?.favouriteName : customer?.customerName;
        dispatch(setSelectedCategory({ id: customer?.customerId, name: customer?.customerName, displayName: dropdownName }));
        dispatch(setRecentSearchId(customer?.id))
        handleClose();
        if (alarmType) {
            navigate(NWC_ALARM_URL);
        }
    }

    const filteredCust = recentDatum?.filter((item) => {
        if (searchType === "customer") {
            return item?.customerName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
        } else if (searchType === "area") {
            return item?.siteList?.some(site => {
                const stateName = prefectures?.find(pref => pref?.value === site?.state)?.label?.toLowerCase();
                return stateName?.includes(searchQuery?.toLowerCase());
            });
        } else if (searchType === "site") {
            return item?.siteList?.some(site =>
                site?.siteName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            );
        }
        else if (searchType === "favorite" && searchQuery != "") {
            return item?.isFavourite && item?.favouriteName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
        }
        else return true;
    });

    return (
        <Box sx={customerTabStyles.recentOuterBox}>
            {recentLoading && !recentData && !recentError ?
                <div className="spinner-wrapper blue-spinner">
                    <Lottie className="spinner" animationData={spinner} autoplay loop />
                </div> :
                !recentLoading && !recentError && recentData && recentData?.length > 0 ? (
                    !!filteredCust?.length ? (
                        filteredCust?.map((customer, index) => {
                            const areaCodes = [
                                ...new Set(customer?.siteList?.map((site) => site?.state)),
                            ];
                            const areas = areaCodes
                                ?.map((code) => getSingleStateName(code))
                                ?.join(", ");
                            const sites = customer?.siteList
                                ?.map((site) => site?.siteName)
                                .join(", ");
                            const headerText =
                                customer?.isFavourite && customer?.favouriteName ? customer?.favouriteName : customer?.customerName;
                            return (
                                <>
                                    {index === 0
                                        && <Divider style={{ marginTop: "16px", marginBottom: "0px" }} />}{" "}
                                    <List sx={customerTabStyles?.listParent} >
                                        <div key={index} >
                                            <ListItem
                                                sx={customerTabStyles.recentListItem}
                                                onClick={() => handleItemSelect(customer)}
                                            >

                                                <div style={{ ...customerTabStyles.listOuterContainer }}>
                                                    <div
                                                        style={{
                                                            visibility: `${!(customer?.isFavourite) && "hidden"}`,
                                                            ...customerTabStyles.listContainer

                                                        }}
                                                    >
                                                        <FavBlueStar fontSize="inherit" />
                                                    </div>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body1"
                                                                sx={customerTabStyles.listItemTypography}
                                                            >
                                                                <div
                                                                    style={customerTabStyles?.recentHeaderText}

                                                                >
                                                                    {headerText}
                                                                </div>
                                                                {customer?.customerName}
                                                                {areas && (
                                                                    <ChevronRight

                                                                        style={customerTabStyles.recentArrowIcon}
                                                                    />
                                                                )}
                                                                {areas}
                                                                {sites && (
                                                                    <ChevronRight

                                                                        style={customerTabStyles.recentArrowIcon}
                                                                    />
                                                                )}
                                                                {sites}
                                                            </Typography>
                                                        }
                                                    />
                                                </div>
                                            </ListItem>
                                            {index <
                                                // recentData?.filter(item => item.siteList?.length > 0)
                                                filteredCust
                                                    ?.length - 1 && <Divider component="li" />}{" "}
                                        </div>
                                    </List >
                                </>

                            );
                        })
                    ) : (noRecentSearchFunction())) : (
                    <>{noRecentFunction()}</>
                )}


        </Box >
    );
};

export default RecentsTab;
