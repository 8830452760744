import React from "react";
import { ReactComponent as BellIcon } from "../../assets/images/BellIcon.svg";
import { ReactComponent as BlueArrow } from "../../assets/images/BlueRightArrow.svg";
import { Card, Typography, Link } from "@mui/material";
function AlarmCard({ totalAlarms, cards }) {
  const handleViewAll = () => {};
  return (
    <Card className="electricityAlarmBox">
      <div className="cardBox">
        <Typography variant="span"> {cards.alarmTitle} </Typography>
      </div>
      <div className="innerContent">
        <span className="electricitySpaned">{<BellIcon />}</span>
        <Typography className="electricityText"> {totalAlarms} </Typography>
        <span className="alarmSpan">
          <Link underline="none" href="#" onClick={handleViewAll}>
            {cards.viewAllButton}
          </Link>
          <BlueArrow />
        </span>
      </div>
    </Card>
  );
}

export default AlarmCard;
