import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { DateRangePicker, Stack, CustomProvider } from 'rsuite';
import enUS from 'rsuite/locales/en_US';
import jaJP from 'rsuite/locales/ja_JP';
import { FaCalendar } from 'react-icons/fa';
import { addDays, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import './DateRangeCalendar.scss';

// Helper function to convert to UTC+9
const convertToUTCPlus9 = (date) => {
  const utcTime = new Date(date.toUTCString().slice(0, -3)); // Convert to UTC
  return new Date(utcTime.getTime() + 9 * 60 * 60 * 1000); // Add 9 hours
};

const DateRangeCalendar = () => {
  const now = new Date();
  const utcPlus9Now = convertToUTCPlus9(now);

  // Calculate predefined ranges adjusted for UTC+9
  const predefinedRanges = [
    {
      label: 'Today',
      value: [startOfDay(utcPlus9Now), utcPlus9Now],
      placement: 'left'
    },
    {
      label: 'Last 24 Hours',
      value: [convertToUTCPlus9(new Date(Date.now() - 24 * 60 * 60 * 1000)), utcPlus9Now],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [startOfDay(addDays(utcPlus9Now, -1)), endOfDay(addDays(utcPlus9Now, -1))],
      placement: 'left'
    },
    {
      label: 'This Week',
      value: [startOfWeek(utcPlus9Now, { weekStartsOn: 1 }), utcPlus9Now],
      placement: 'left'
    },
    {
      label: 'Last Week',
      value: [startOfWeek(addDays(utcPlus9Now, -7), { weekStartsOn: 1 }), endOfWeek(addDays(utcPlus9Now, -7), { weekStartsOn: 1 })],
      placement: 'left'
    },
    {
      label: 'This Month',
      value: [startOfMonth(utcPlus9Now), utcPlus9Now],
      placement: 'left'
    },
    {
      label: 'Last Month',
      value: [startOfMonth(addDays(utcPlus9Now, -30)), endOfMonth(addDays(utcPlus9Now, -30))],
      placement: 'left'
    },
    {
      label: 'This Year',
      value: [startOfYear(utcPlus9Now), utcPlus9Now],
      placement: 'left'
    },
    {
      label: 'Last Year',
      value: [startOfYear(addDays(utcPlus9Now, -365)), endOfYear(addDays(utcPlus9Now, -365))],
      placement: 'left'
    }
  ];

  const [utcPlus9DateTime, setUtcPlus9DateTime] = useState(utcPlus9Now.toLocaleString());
  const [shortCurLabel, setShortCurLabel] = useState(predefinedRanges[0]); // Default to "Today"
  const [activeShortcut, setActiveShortcut] = useState('Today'); // Default to "Today"
  const [isCustomRange, setIsCustomRange] = useState(false); // Track custom range
  const [customRange, setCustomRange] = useState(null); // Track custom range details

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const utcPlus9 = convertToUTCPlus9(now);
      setUtcPlus9DateTime(utcPlus9.toLocaleString());
    };

    updateTime(); // Update the time on component mount

    const intervalId = setInterval(updateTime, 1000); // Update every second

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const handleRangeChange = (range) => {
    if (range[0] && range[1]) {
      const duration = Math.abs(range[1] - range[0]);
      const isValidRange = duration <= 24 * 60 * 60 * 1000 || range[0].getFullYear() === range[1].getFullYear(); // 24 hours or same year

      if (isValidRange) {
        setIsCustomRange(true); // Mark as custom range
        setActiveShortcut('Custom'); // Set active to custom
        setCustomRange(range);
        setShortCurLabel({
          label: 'Custom',
          value: range
        });
      } else {
        alert('Invalid range. Please select a range within 24 hours or within the same year.');
      }
    }
  };

  const handleShortcutClick = (shortcut) => {
    setIsCustomRange(false); // Mark as not custom range
    setActiveShortcut(shortcut.label); // Set the active shortcut
    setShortCurLabel(shortcut);
  };

  // Include the custom range in the list of ranges
  const rangesWithCustom = [
    ...predefinedRanges,
    {
      label: 'Custom',
      value: customRange || [startOfDay(utcPlus9Now), utcPlus9Now],
      placement: 'left'
    }
  ];

  return (
    <React.Fragment>
      <Button variant="text">{shortCurLabel?.label || 'Select Range'}</Button>
      <Stack spacing={10} direction="column" alignItems="flex-end">
        <CustomProvider locale={enUS || jaJP}>
          <DateRangePicker
            placement="autoVerticalEnd"
            format="yyyy/MM/dd HH:mm"
            defaultValue={[startOfDay(utcPlus9Now), utcPlus9Now]} // Set the default value
            ranges={rangesWithCustom.map((range) => ({
              ...range,
              className: activeShortcut === range.label ? 'active-shortcut' : ''
            }))}
            isoWeek
            caretAs={FaCalendar}
            onChange={handleRangeChange} // Handle custom date selection
            onShortcutClick={handleShortcutClick} // Handle shortcut selection
          />
        </CustomProvider>
      </Stack>
      <div>
        UTC+9
        <p>{utcPlus9DateTime}</p>
      </div>
    </React.Fragment>
  );
};

export default DateRangeCalendar;
