import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance, { refreshToken } from "../../services/axiosInstance";
import { setSiteApiRequest } from "../../common/utils/CommonUtils";
import { siteStepKeys } from "../../components/setting/siteDetails/siteDetails.Data";
const initialState = {
  loading: "idle",
  selectedCustomer: "",
  editSiteId: "",
  apiError: false,
  formStatus: "",
  isReduxUpdated: false,
  access: {},
  refresh: {},
  verifyEmailToken: "",
  customersList: [],
  siteList: [],
  siteStatus: "",
  activeCustomerId: "",
  emsConnectionData: {},
  webAccessConnectionData: {},
  homeSiteList: [],
  accordionList: [],
  siteFormData: {
    siteDetail: {
      name: "",
      displayName: "",
      description: "",
      imageUrl: null,
      thumbnailUrl: null,
      id: "",
      nextStep: "",
      fileName: "",
    },
    siteAddress: {
      country: "",
      postalCode: "",
      state: "",
      city: "",
      address1: "",
      id: "",
      nextStep: "",
    },
    edgeDevice: {
      webAccessUrl: "",
      webAccessUser: "",
      webAccessPassword: "",
      emsAccessPassword: "",
      emsAccessUrl: "",
      emsAccessUser: "",
      id: "",
      nextStep: "",
      lastOnline: null,
      deviceStatus: "",
      webAccessConnectionVerified: false,
      emsConnectionVerified: false,
      emsProjectInfo: [],
      webAccessProjectInfo: [],
      lastSyncTime: null,
      tagCount: 0,
    },
    emailNotification: {},
  },
  emsConnectionVerifiedStore: false,
  webAccessConnectionVerifiedStore: false,
  editableCust: {
    email: "",
    password: "",
    name: "",
    role: "",
    country: "",
    postalCode: "",
    state: "",
    city: "",
    address1: "",
    contactNumber: "",
    customerRole: "",
  },
  _id: "",
  formInfo: {
    email: "admin@nwc.com",
    password: "password1",
    checkbox: false,
    // phone: "",
  },
  user: {},
  defaultLanguage: "en",
};


function isValidBase64(str) {
  try {
      // A Base64 string must be a valid base64-encoded string
      return btoa(atob(str)) === str;
  } catch (e) {
      return false;
  }
}


// Define a function to create async thunk with rejection handling
const createAsyncThunkWithRejection = (name, thunkFn) => {
  return createAsyncThunk(name, async (arg, thunkAPI) => {
    try {
      return await thunkFn(arg);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

// add customer api //
export const addCustomerApi = createAsyncThunkWithRejection(
  "customerInfo/addCustomerApi",
  async ({ url, payload, token }) => {
    try {
      const response = await axiosInstance.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// get all customer api
export const getAllCustomersApi = createAsyncThunkWithRejection(
  "customerInfo/getAllCustomers",
  async (payload) => {
    try {
      const response = await axios.get(payload?.url, {
        headers: {
          Authorization: `Bearer ${payload?.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      await refreshToken();
      throw error;
    }
  }
);

// update customer api //
export const updateCustomerApi = createAsyncThunkWithRejection(
  "customerInfo/updateCustomerApi",
  async ({ url, editablePayload, token }) => {
    try {
      const response = await axiosInstance.patch(url, editablePayload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);
// update customer api //
export const createSiteApi = createAsyncThunkWithRejection(
  "customerInfo/createSite",
  async ({ url, payload, token }) => {
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
);

// get all site api
export const getAllSiteApi = createAsyncThunkWithRejection(
  "customerInfo/getAllSiteApi",
  async (payload) => {
    try {
      const response = await axios.get(payload?.url, {
        headers: {
          Authorization: `Bearer ${payload?.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// get one site api //
export const getSingleSiteApi = createAsyncThunkWithRejection(
  "customerInfo/getSingleSiteApi",
  async ({ url, siteId, token }) => {
    const updatedUrl = `${url}/${siteId}`;
    try {
      const response = await axios.get(updatedUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
);
// site update api
export const updateSiteApi = createAsyncThunkWithRejection(
  "customerInfo/updateSite",
  async ({ url, payload, token }) => {
    try {
      const response = await axios.patch(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          // "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);
// site delet api
export const deleteSiteApi = createAsyncThunkWithRejection(
  "customerInfo/deleteSite",
  async ({ url, siteId, token }) => {
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          siteId: siteId,
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
);
// delete image api
export const deleteImageApi = createAsyncThunkWithRejection(
  "customerInfo/deleteImageApi",
  async ({ url, payload, token }) => {
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          imageUrl: payload,
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
);

const customerSlice = createSlice({
  name: "customerInfo",
  initialState,
  reducers: {
    addCustomer: (state, action) => {
      state.editableCust = action?.payload;
    },
    selectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    getEditSiteId: (state, action) => {
      state.editSiteId = action.payload;
    },
    updateAccordionList: (state, action) => {
      state.accordionList = action.payload;
    },
    updateCustomerDetail: (state, action) => {
      state.verifyEmailToken = action.payload;
    },
    updateWebAccessVerified: (state, action) => {
      state.webAccessConnectionVerifiedStore = action.payload;
    },
    updateEmsAccessVerified: (state, action) => {
      state.emsConnectionVerifiedStore = action.payload;
    },
    updateApiLoader: (state, action) => {
      state.loader = action.payload;
    },
    updateHomeSiteList: (state, action) => {
      state.homeSiteList = action.payload;
    },
    updateSiteDetail: (state, action) => {
      state.siteFormData.siteDetail = action?.payload;
    },
    updateSiteFormInitialState: (state, action) => {
      state.siteFormData = action?.payload;
    },
    updateActiveCustomerId: (state, action) => {
      state.activeCustomerId = action?.payload;
    },
    // updateSiteAddress: (state, action) => {
    //   state.siteFormData.siteAdress = action?.payload;
    // },
    updateEdgeDevice: (state, action) => {
      state.siteFormData.edgeDevice = {
        ...state.siteFormData.edgeDevice,
        ...action?.payload,
      };
    },
    // updateEmailNotification: (state, action) => {
    //   state.siteFormData.emailNotification = action?.payload;
    // },
    updateCustomerList: (state, action) => {
      state.customersList = action?.payload?.results;
    },
    updateSite: (state, action) => {
      state.siteFormData[action.payload.key] = {
        ...state.siteFormData[action.payload.key],
        ...action.payload.siteData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCustomerApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(addCustomerApi.fulfilled, (state, action) => {
        state.customersList = [...state?.customersList, action?.payload?.data];
        state.loading = "idle";
      })
      .addCase(addCustomerApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          // Handle 401 unauthorized error
          state.apiError = true;
        } else {
          // Handle other errors
          state.apiError = true;
        }
      })
      .addCase(getAllCustomersApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAllCustomersApi.fulfilled, (state, action) => {
        state.customersList = action?.payload?.data?.results; // Update customerList
        state.loading = "idle";
      })
      .addCase(getAllCustomersApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          // Handle 401 unauthorized error
          state.apiError = true;
        } else {
          // Handle other errors
          state.apiError = true;
        }
        state.loading = "idle";
      })
      .addCase(getSingleSiteApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getSingleSiteApi.fulfilled, (state, action) => {
        state.siteFormData = { ...action?.payload?.data };
        let emsAccessPassword = action?.payload?.data?.emsAccessPassword;
        let decodedEmsPassword = '';
        
        
        if (emsAccessPassword && isValidBase64(emsAccessPassword)) {
            decodedEmsPassword = atob(emsAccessPassword);
        } else {
          decodedEmsPassword = emsAccessPassword;
            console.error('Invalid Base64 string:', emsAccessPassword);
            // Handle the invalid case, maybe assign a default value or notify the user
        }
        // Assuming edgeDevice data is part of the payload
        state.siteFormData.edgeDevice = {
          webAccessUrl: action?.payload?.data?.webAccessUrl,
          webAccessUser: action?.payload?.data?.webAccessUser,
          webAccessPassword: action?.payload?.data?.webAccessPassword,
          emsAccessPassword: emsAccessPassword,
          emsAccessUrl: action?.payload?.data?.emsAccessUrl,
          emsAccessUser: action?.payload?.data?.emsAccessUser,
        };
        state.loading = "idle";
      })
      .addCase(getSingleSiteApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          // Handle 401 unauthorized error
          state.apiError = true;
        } else {
          // Handle other errors
          state.apiError = true;
        }
        state.loading = "idle";
      })
      .addCase(updateCustomerApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updateCustomerApi.fulfilled, (state, action) => {
        const updatedCustomer = action?.payload;
        state.customersList = state?.customersList?.map((customer) => {
          return customer?.id === updatedCustomer?.id
            ? updatedCustomer
            : customer;
        });
        state.loading = "idle";
      })
      .addCase(updateCustomerApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          // Handle 401 unauthorized error
          state.apiError = true;
        } else {
          // Handle other errors
          state.apiError = true;
        }
        state.loading = "idle";
      })
      .addCase(updateSiteApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updateSiteApi.fulfilled, (state, action) => {
        state.siteFormData = { ...action?.payload?.data };
        // state.siteFormData = { ...action?.payload?.data };
        let siteStepKeysVal = { ...siteStepKeys };
        let resVal = setSiteApiRequest(siteStepKeysVal, action?.payload?.data );
        let emsAccessPassword = action?.payload?.data?.emsAccessPassword;
        let decodedEmsPassword = '';
        
        
        if (emsAccessPassword && isValidBase64(emsAccessPassword)) {
            decodedEmsPassword = atob(emsAccessPassword);
        } else {
          decodedEmsPassword = emsAccessPassword;
            console.error('Invalid Base64 string:', emsAccessPassword);
            // Handle the invalid case, maybe assign a default value or notify the user
        }

        // Decode existing Base64 password
        // const decodedEmsPassword = atob(action?.payload?.data.emsAccessPassword);



        // Update objects with the encoded password
        const updatedActionPayload = {
          ...action?.payload?.data ,
          emsAccessPassword: decodedEmsPassword
        };

        const updatedResval = {
          ...resVal,
          edgeDevice: {
              ...resVal.edgeDevice,
              emsAccessPassword: decodedEmsPassword
          }
        };

        // Combine the objects
        const combinedObject = {
          ...updatedActionPayload,
          siteDetail: updatedResval.siteDetail,
          siteAddress: updatedResval.siteAddress,
          edgeDevice: {
              ...updatedResval.edgeDevice,
              webAccessPassword: updatedActionPayload.webAccessPassword
          }
        };
        state.siteFormData =combinedObject
        state.loading = "idle";
      })
      .addCase(updateSiteApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          state.apiError = true;
        } else {
          state.apiError = true;
        }
      })
      .addCase(deleteSiteApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(deleteSiteApi.fulfilled, (state, action) => {
        const updatedSite = state?.siteList?.filter((item) => {
          return item.id !== action?.payload?.data?.id;
        });
        state.siteList = updatedSite;
        state.loading = "idle";
      })
      .addCase(deleteSiteApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          state.apiError = true;
        } else {
          state.apiError = true;
        }
      })
      .addCase(deleteImageApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(deleteImageApi.fulfilled, (state, action) => {
        state.loading = "idle";
      })
      .addCase(deleteImageApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          state.apiError = true;
        } else {
          state.apiError = true;
        }
      })
      .addCase(createSiteApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createSiteApi.fulfilled, (state, action) => {
        state.siteList = [...state?.siteList, action?.payload?.data];
        // state.siteFormData = { ...action?.payload?.data };
        let siteStepKeysVal = { ...siteStepKeys };
        let resVal = setSiteApiRequest(siteStepKeysVal, action?.payload?.data );

       
        // Decode existing Base64 password
        const decodedEmsPassword = atob(action?.payload?.data.emsAccessPassword);

 

      // Update objects with the encoded password
      const updatedActionPayload = {
          ...action?.payload?.data ,
          emsAccessPassword: decodedEmsPassword
      };

      const updatedResval = {
          ...resVal,
          edgeDevice: {
              ...resVal.edgeDevice,
              emsAccessPassword: decodedEmsPassword
          }
      };

      // Combine the objects
      const combinedObject = {
          ...updatedActionPayload,
          siteDetail: updatedResval.siteDetail,
          siteAddress: updatedResval.siteAddress,
          edgeDevice: {
              ...updatedResval.edgeDevice,
              webAccessPassword: updatedActionPayload.webAccessPassword
          }
      };
        state.siteFormData =combinedObject
        
        state.loading = "idle";
      })
      .addCase(createSiteApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          state.apiError = true;
        } else {
          state.apiError = true;
        }
      })
      .addCase(getAllSiteApi.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getAllSiteApi.fulfilled, (state, action) => {
        state.siteList = action?.payload?.data?.results;
        state.loading = "idle";
      })
      .addCase(getAllSiteApi.rejected, (state, action) => {
        if (action?.payload && action?.payload?.status === 401) {
          // Handle 401 unauthorized error
          state.apiError = true;
        } else {
          // Handle other errors
          state.apiError = true;
        }
      });
  },
});

export const {
  updateCustomerDetail,
  updateApiLoader,
  addCustomer,
  updateSiteDetail,
  updateWebAccessVerified,
  updateEmsAccessVerified,
  selectedCustomer,
  getEditSiteId,
  // updateSiteAddress,
  updateEdgeDevice,
  // updateEmailNotification,
  updateSiteFormInitialState,
  updateCustomerList,
  updateSite,
  updateAccordionList,
  updateActiveCustomerId,
  updateHomeSiteList,
} = customerSlice.actions;
export default customerSlice.reducer;
