import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Divider, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import FilterSearch from "../../common/filterSearch/FilterSearch";
import {
  deleteSiteApi,
  getAllCustomersApi,
  getAllSiteApi,
  updateCustomerList,
  getEditSiteId,
} from "../../redux/slices/customerSlice";
import { ReactComponent as GreenCircleIcon } from "../../assets/images/green_circle.svg";
import { ReactComponent as RedCircle } from "../../assets/images/redCircle.svg";
import { ReactComponent as GreyCircle } from "../../assets/images/greyCircle.svg";
import { getStateLabelForListing } from "../../common/utils/CommonUtils";
import {
  getAllCustomeWithPaginationEndPoint2,
  getSiteWithPaginationEndPoint,
  siteEndPoint,
} from "../../services/apiConfig";
import NoSite from "./NoSite";
import CustomTable from "../../common/customTable/CustomTable";
import {
  siteHeaderStructure,
  sortingKeys,
} from "./siteDetails/siteDetails.Data";
import CommonPagination from "../../common/reusable-comps/custom-pagination/CustomPagination";
import {
  getRequestCustomerApi,
  formatDate,
} from "../../common/utils/CommonUtils";
import { ApiPayload } from "./ApiAction";
import SmallReusableModal from "../../common/reusable-comps/modals/SmallReusableModal";
import CustomButton from "../../common/buttons/CustomButton";
import "./setting.scss";
import CustomAlert from "../../common/reusable-comps/alert-bar/CustomAlert";
import { ReactComponent as RedErrorIcon } from "../../assets/images/RedErrorIcon.svg";

function SiteList(props) {
  const {
    localSiteList,
    setLocalSiteList,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    setSiteScreenType,
    selectedCustomerData,
    customerId,
    siteLength,
    customerSiteList,
    siteListCount,
    sitePagesList,
    setIsSiteEdit,
    setEditSiteId,
    setTriggerCustApi,
    setSiteId,
    setSiteLength,
    currentSiteFilter,
    setcurrentSiteFilter,
    siteScreenType,
    currentCustFilter,
    currentCustPage,
    custRowsPerPage,
    setIsSiteCreated,

  } = props;
  const [startIndex, setStartIndex] = useState(0);
  const [enteredText, setEnteredText] = useState("");
  const [listCount, setListCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [siteCount, setSiteCount] = useState("");
  const [endItemIndex, setEndIndexItem] = useState(0);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const dispatch = useDispatch();
  const refToTop = useRef(0);
  const { t } = useTranslation();
  const [rowDataSelected, setrowDataSelected] = useState({});
  const [showErrorBar, setShowErrorBar] = useState(true);
  const {
    settingsPageContent,
    deleteModal,
    siteSorting,
    gridAction,
    syncModal,
  } = t("mainContainer");
  const { customerDetails, syncData } =
    settingsPageContent?.siteManagementContent;
  const siteLists = useSelector((state) => state?.customerInfo?.siteList);
  const alertProps = {
    border: {
      borderLeft: "3px solid #DA1E284D",
      borderTop: "1px solid #DA1E284D",
      borderRight: "1px solid #DA1E284D",
      borderBottom: "1px solid #DA1E284D",
    },
    backgroundColor: "#FFF1F1",
    alertHeader: syncData?.alert?.alertHeader,
    alertText: syncData?.alert.alertText[0],
  };
  const resetSiteList = (res) => {
    let result =
      res.payload?.data?.results?.map((res) => {
        const updatedStatus = res?.deviceStatus; // Assuming deviceStatus comes from the response
        const filteredActionMenu = gridAction?.filter((item, idx) => {
          return !(idx === 2 && updatedStatus !== "online");
        });
        return {
          ...res,
          deviceStatus: updatedStatus,
          actionMenu: filteredActionMenu,
          updatedDate: (
            <div>
              {updatedStatus === "online" ? (
                <GreenCircleIcon />
              ) : updatedStatus === "offline" ? (
                <RedCircle />
              ) : updatedStatus === "disable" ? (
                <GreyCircle />
              ) : null}
              {res?.lastOnline && formatDate(res?.lastOnline)}
            </div>
          ),
        };
      }) ?? [];
    return result;
  };
  useEffect(() => {
    const payload = {
      url: getAllCustomeWithPaginationEndPoint2(
        getRequestCustomerApi({
          page: currentCustPage,
          limit: custRowsPerPage,
          sortBy: sortingKeys?.[currentCustFilter],
        })
      ),
      token: localStorage.getItem("accessToken"),
    };
    dispatch(getAllCustomersApi(payload)).then((res) => {
      if (res?.payload?.status == "200") {
        dispatch(updateCustomerList(res?.payload?.data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // siteScreenType,
    currentCustFilter,
    // currentCustPage,
    // custRowsPerPage,
  ]);

  const handleSearchChange = (event) => {
    setEnteredText(event?.target?.value);
    let requestParams = getRequestCustomerApi({
      name: event?.target?.value,
      limit: rowsPerPage,
      page: currentPage,
      sortBy: sortingKeys?.[currentSiteFilter],
    });
    handlePageChange(currentPage, rowsPerPage, "sortOrder", requestParams);
  };
  const handleActionSelection = (selectedOrder) => {
    setcurrentSiteFilter(selectedOrder);
    let requestParams = getRequestCustomerApi({
      name: enteredText,
      limit: rowsPerPage,
      page: currentPage,
      sortBy: sortingKeys?.[selectedOrder],
    });
    handlePageChange(currentPage, rowsPerPage, "sortOrder", requestParams);
  };
  // Added `t` as a dependency
  const handlePageChange = (
    newPage,
    newRowsPerPage,
    keyOfAction,
    reqParams
  ) => {
    setRowsPerPage(newRowsPerPage);
    refToTop.current && refToTop.current.scrollIntoView();
    if (
      ["itemsPerPage", "previous", "next", "sortOrder", "pageChange"]?.includes(
        keyOfAction
      )
    ) {
      setCurrentPage(newPage); // Update currentPage state
    }
    dispatch(
      getAllSiteApi(
        ApiPayload(
          getSiteWithPaginationEndPoint(`${reqParams}&customerId=${customerId}`)
        )
      )
    ).then((res) => {
      if (res?.payload?.status == "200") {
        let result = resetSiteList(res);
        setSiteCount(res?.payload?.data?.totalResults || 0);
        setLocalSiteList(result);
        setListCount(res?.payload?.data?.totalResults || 0);
        setSiteLength(res?.payload?.data?.totalResults || 0);
      }
    });
  };

  const handleCloseSyncModal = () => {
    setOpenSyncModal(false);
  };
  const renderButtonText = (
    postLoading,
    testConnectionBtn,
    postError,
    postData
  ) => { };
  const renderIcon = () => { };
  const renderSyncModalFooter = () => {
    return (
      <div>
        <CustomButton
          variant="text"
          onClick={handleCloseSyncModal}
          marginRight=".5rem"
          width="108px"
          padding="11px 16px"
        >
          {syncModal?.actionBtnCancel}
        </CustomButton>
        <Button
          variant="contained"
          onClick={() => handleSiteDelete(selectedSiteId)}
          color="primary"
          sx={{ width: "168px", height: "40px" }}
        >
          {/* {renderButtonText(postLoading, testConnectionBtn, postError, postData)} */}
          <span className="inline-icon">{renderIcon()}</span>
        </Button>
      </div>
    );
  };
  const syncModalChildren = (siteName) => {
    return (
      <Box>
        <Typography variant="span">
          {syncModal?.content1} {siteName} {syncModal?.content2}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-start", marginY: 3 }}>
          <Box sx={{ paddingRight: 4 }}>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              {syncModal?.header1}
            </Typography>
            <Typography variant="span">2024/05/20 07:01:10</Typography>
          </Box>
          <Box>
            <Typography variant="body2" style={{ marginBottom: "8px" }}>
              {syncModal?.header2}
            </Typography>
            <Typography variant="span">1100</Typography>
          </Box>
        </Box>
        <CustomAlert
          severity="error"
          open={showErrorBar}
          {...alertProps}
          onClose={() => setShowErrorBar(false)}
          icon={
            <span sx={{ backgroundColor: "white" }}>
              <RedErrorIcon color="white" />
            </span>
          }
        ></CustomAlert>
      </Box>
    );
  };
  const actionReqHandle = (actionVal, rowVal) => {
    dispatch(getEditSiteId(rowVal?.id));
    setrowDataSelected(rowVal);
    if (rowVal?.actionMenu?.length === 3) {
      if (actionVal === rowVal?.actionMenu[0]) {
        setEditSiteId(rowVal?.id);
        setSiteScreenType("editSite");
        setIsSiteEdit(true);
      } else if (actionVal === rowVal?.actionMenu[2]) {
        setSelectedSiteId(rowVal?.id);
        setModalOpen(true);
      }
    } else if (rowVal?.actionMenu?.length === 4) {
      if (actionVal === rowVal?.actionMenu[0]) {
        setEditSiteId(rowVal?.id);
        setSiteScreenType("editSite");
        setIsSiteEdit(true);
      } else if (actionVal === rowVal?.actionMenu[3]) {
        setSelectedSiteId(rowVal?.id);
        setModalOpen(true);
      } else if (actionVal === rowVal?.actionMenu[2]) {
        setOpenSyncModal(true);
      }
    }
  };

  useEffect(() => {
    setEnteredText("");
    // setRowsPerPage(10);
    // setCurrentPage(1);
    setLocalSiteList(customerSiteList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSiteList, setLocalSiteList]);

  useEffect(() => {
    // alert(siteListCount);
    setSiteCount(siteListCount);
    setSiteLength(siteListCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteListCount, setSiteLength]);

  const handleClear = () => {
    setEnteredText("");
    let reqParams = getRequestCustomerApi({
      name: "",
      limit: rowsPerPage,
      page: currentPage,
      sortBy: sortingKeys?.[currentSiteFilter],
    });

    dispatch(
      getAllSiteApi(
        ApiPayload(
          getSiteWithPaginationEndPoint(`${reqParams}&customerId=${customerId}`)
        )
      )
    ).then((res) => {
      if (res?.payload?.status == "200") {
        let result = resetSiteList(res);
        setSiteCount(res?.payload?.data?.totalResults || 0);
        setLocalSiteList(result);
        setListCount(res?.payload?.data?.totalResults || 0);
        setSiteLength(res?.payload?.data?.totalResults || 0);
      }
    });
  };
  // api call every one minute
  useEffect(() => {
    const interval = setInterval(() => {
      let requestParams = getRequestCustomerApi({
        name: enteredText,
        limit: rowsPerPage,
        page: currentPage,
        sortBy: sortingKeys?.[currentSiteFilter],
      });
      handlePageChange(currentPage, rowsPerPage, "sortOrder", requestParams);
    }, 60000); // 1 minute in milliseconds
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, siteScreenType, rowsPerPage, customerId]);
  useEffect(() => {
    let result = resetSiteList(
      { payload: { data: { results: customerSiteList } } },
      gridAction
    );
    setLocalSiteList(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const handleCloseSmallModal = () => {
    setModalOpen(false);
  };

  const smallModalChildren = () => {
    return <h5>{deleteModal?.content}</h5>;
  };

  const handleSiteDelete = (id) => {
    const apiPayload = {
      token: localStorage?.getItem("accessToken"),
      url: siteEndPoint,
      siteId: selectedSiteId,
    };
    dispatch(deleteSiteApi(apiPayload)).then((res) => {
      setModalOpen(false);
      setSelectedSiteId(null); // Clear the selected site ID
      let requestParams = getRequestCustomerApi({
        name: enteredText,
        limit: rowsPerPage,
        page: currentPage,
        sortBy: sortingKeys?.[currentSiteFilter],
      });
      handlePageChange(currentPage, rowsPerPage, "pageChange", requestParams);
      if (res?.payload?.status == 200) {
        setTriggerCustApi(true);
      }
    });
  };

  const renderFooter = () => {
    return (
      <div>
        <CustomButton
          variant="text"
          onClick={handleCloseSmallModal}
          marginRight=".5rem"
          width="108px"
          padding="11px 16px"
        >
          {deleteModal.actionBtnCancel}
        </CustomButton>
        <Button
          variant="contained"
          onClick={() => handleSiteDelete(selectedSiteId)}
          color="error"
          sx={{ width: "117px", height: "40px" }}
        >
          {deleteModal.actionBtnDelete}
        </Button>
      </div>
    );
  };
  const columnStyles = {
    2: { width: "200px" }, // Increase the width of the third column
  };
  const handleAddCustomer = () => {
    dispatch(getEditSiteId(""));
    setSiteScreenType("addSite");
    setSiteId("");
    setEditSiteId("")
    setIsSiteCreated(false);
  };

  const transformApiResponse = (data) => {
    return data.map((item) => ({
      ...item,
      state: getStateLabelForListing(item.state),
    }));
  };
  const transformedData = transformApiResponse(localSiteList);
  return (
    <>
      {siteListCount > 0 && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "white",
            paddingLeft: "24px",
            border: "1px solid #c6c6c6",
            marginRight: "8px",
            marginLeft: "8px",
            height: "100%",
          }}
          className="siteListWidth"
        >
          <Box
            style={{
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.25px",
              textAlign: "left",
              paddingTop: "24px",
            }}
          >
            {selectedCustomerData?.displayName ?? ""}
          </Box>
          <FilterSearch
            handleClear={handleClear}
            btnText={customerDetails.addSiteBtn}
            boxGap="16px"
            enteredText={enteredText}
            handleSearchChange={handleSearchChange}
            handleAddCustomer={handleAddCustomer}
            sortOptions={siteSorting}
            refToTop={() => { }}
            boxPadding={"0px 24px 0px 0px"}
            addButtonWidth="124px"
            handleActionSelection={handleActionSelection}
            buttonMarginLeft="auto"
            customFlexStyle="1 1 80%"
            searchPlaceholder={customerDetails.searchPlaceholder}
          />
          <Box
            sx={{
              height: "calc(100% - 154px)",
              marginTop: "16px",
              overflowY: "auto",
              paddingRight: "20px",
            }}
          >
            <CustomTable
              setLocalSiteList={setLocalSiteList}
              siteLength={siteLength}
              tableData={transformedData}
              headerStructure={siteHeaderStructure}
              actionRequest={actionReqHandle}
              columnStyles={columnStyles}
              tooltipColumn={1}
              sortOptions={siteSorting}
              handleTableSorting={handleActionSelection}
              siteListCount={siteListCount}
            />
            {openSyncModal && (
              <SmallReusableModal
                open={openSyncModal}
                handleClose={handleCloseSyncModal}
                title={syncModal.title}
                footer={renderSyncModalFooter()}
                divider={false}
                dialogWidth="414px"
                // dialogHeight="212px"
                titleAndIconPadding="0"
                contentPadding="0 0 32px 0"
                // minWidth="414px"
                minHeight="212px"
              >
                {syncModalChildren(rowDataSelected?.name)}
              </SmallReusableModal>
            )}
          </Box>
          <Divider style={{ marginLeft: "-24px" }} />
          <Box sx={{ height: "40px" }}>
            <CommonPagination
              endItemIndex={endItemIndex}
              setEndIndexItem={setEndIndexItem}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
              currentFilter={currentSiteFilter}
              count={siteCount}
              page={currentPage}
              pagesList={sitePagesList}
              onChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              pageNumberEnabled={true}
              listCount={listCount}
              arrowWidth="7%"
            // arrowBoxMarginRight="8px"
            />
          </Box>
          {modalOpen && (
            <SmallReusableModal
              open={modalOpen}
              handleClose={handleCloseSmallModal}
              title={deleteModal.title}
              footer={renderFooter()}
              divider={false}
              dialogWidth="410px"
              dialogHeight="152px"
              titleAndIconPadding="0"
              contentPadding="0 0 32px 0"
            >
              {smallModalChildren()}
            </SmallReusableModal>
          )}
        </Box>
      )}
      {(siteListCount == "empty" || siteListCount == "blank") && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: 1,
            marginLeft: "-8px",
          }}
        >
          <NoSite
            setSiteScreenType={setSiteScreenType}
            siteListCount={siteListCount}
            customerName={selectedCustomerData?.displayName}
          />
        </div>
      )}
    </>
  );
}

export default SiteList;
