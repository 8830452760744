// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
  display: flex;
  height: 100%;
  width: 98.5%;
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/components/alarm/AlarmHome.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":[".flex-container{ \n    display: flex;\n    height: 100%;\n    width: 98.5%;\n    margin-top: 16px;\n    flex-direction: column;\n    align-items: flex-start;\n    align-self: stretch;\n    border-radius: 4px;\n    border: 1px solid #E0E0E0; \n    background: #FFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
