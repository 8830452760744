import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Box, Tab, Button, Alert } from '@mui/material';
import Stack from '@mui/material/Stack';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AllCustomerTabContent from './allCustomerTab/AllCustomerTab';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as DangerIcon } from "../../../../../assets/images/danger.svg";
import usePost from '../../../../../common/hook/usePost';
import { recentSearchURL } from '../../../../../services/apiConfig';
import { setRecentSearchId, setMyFavoriteLists } from '../../../../../redux/slices/customerFilterSlice';
import { prefectures } from '../../../../setting/CustomerData';
import FavoritesContent from './favoritesContent/FavoritesContent';
import { getSavedFavorite } from '../../../../../services/apiConfig';
import useFetch from '../../../../../common/hook/useFetch';
import CommonSearch from './CommonSearch';
import { customerTabStyles } from './allCustomerTab/AllCustomerTabStyle';
import RecentsTab from './recentsContent/RecentsTab';

const Tabs = ({ handleClose, setbreadcrumbData, tabActiveFrom, setTabActive }) => {
    const dispatch = useDispatch();

    const [allCustomerData, setAllCustomerData] = useState({}); // State for All Customers tab data
    const [favoritesData, setFavoritesData] = useState({}); // State for Favorites tab data
    const [recentsData, setRecentsData] = useState({}); // State for Recents tab data
    const [siteAvailable, setSiteAvailable] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [searchType, setSearchType] = useState('customer')
    const [searchQuery, setSearchQuery] = useState('')
    const [value, setValue] = useState(tabActiveFrom);
    const userId = localStorage.getItem("userId");

    // Language Parameter
    const { t } = useTranslation();
    const { customerTopNavigation } = t("mainContainer");
    const { actions, tabsText, areaErrorMessage, siteErrorMessage } = customerTopNavigation;

    const {
        data: savedFavorite,
        error: savedFavoriteError,
        loading: savedFavoriteLoading,
        fetchData: getAllSavedFavorite,
    } = useFetch();

    useEffect(() => {
        if (savedFavorite && !savedFavoriteError && !savedFavoriteLoading) {
            dispatch(setMyFavoriteLists(savedFavorite));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFavorite, savedFavoriteError, savedFavoriteLoading])

    const handleChange = (event, newValue) => {
        const targetElement = event?.target;

        if (targetElement?.classList?.contains('MuiTab-root')) {
            setValue(newValue);
            setTabActive(newValue);

        }

    };


    const {
        loading: recenSearchLoading,
        error: recenSearchError,
        data: recenSearchData,
        postData: doPostrecetSearch,
    } = usePost();


    useEffect(() => {
        if (!recenSearchLoading && !recenSearchError && recenSearchData && allCustomerData?.selectedSites.length !== 0 && allCustomerData?.selectedAreas.length !== 0) {
            dispatch(setRecentSearchId(recenSearchData?.result?.id));
            setbreadcrumbData(allCustomerData)
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recenSearchLoading, recenSearchError, recenSearchData])

    const handleSubmit = () => {
        switch (tabActiveFrom) {
            case '1':
                console.log('Submit All Customers Data:', allCustomerData);
                // Transforming the selectedSites to the desired format
                const mappedSites = allCustomerData?.selectedSites.map(site => ({
                    siteId: site.id,
                    state: prefectures?.find(pref => pref?.label === site?.state)?.value,
                    siteName: site.name
                }));

                const recentSearchPayload = {
                    "userId": userId,
                    "customerId": allCustomerData?.selectedCategory?.id,
                    "customerName": allCustomerData?.selectedCategory?.displayName,
                    "siteList": mappedSites,
                    "lastUsed": new Date().getTime()
                } 
                if (allCustomerData?.selectedSites.length !== 0 && allCustomerData?.selectedAreas.length !== 0) {
                 
                    doPostrecetSearch(recentSearchURL, recentSearchPayload);
                }

                // Implement submission logic for All Customers data
                break;
            case '2':
                console.log('Submit Favorites Data:', favoritesData);
                // Implement submission logic for Favorites data
                break;
            case '3':
                console.log('Submit Recents Data:', recentsData);
                // Implement submission logic for Recents data
                break;
            default:
                console.log('No tab selected');
        }
    };

    const handleCancel = () => {
        switch (tabActiveFrom) {
            case '1':
                setAllCustomerData({});
                break;
            case '2':
                setFavoritesData({});
                break;
            case '3':
                setRecentsData({});
                break;
            default:
                console.log('No tab selected');
        }
        handleClose()
    };

    const cancelBtnStyle = { height: "32px", marginRight: "10px", padding: "2px 23.5px", fontWeight: "regular" }
    const submitBtnStyle = { height: "32px", padding: "2px 29.88px", fontWeight: "regular" }

    const handleSearch = (searchTyped, searchQuerys, activeTabs) => {
        setSearchType(searchTyped)
        setSearchQuery(searchQuerys)
        setTabActive(activeTabs)
    };


    return (
        <Box sx={{ typography: "body1", width: "846px", paddingTop: "16px" }}>
            <TabContext value={tabActiveFrom}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="tabs" className="tabs-header">
                        <Tab label={tabsText["tabOne"]} value="1" />
                        <Tab label={tabsText["tabTwo"]} value="2" />
                        <Tab label={tabsText["tabThree"]} value="3" />
                        <div style={customerTabStyles?.searchContainer} >
                            <CommonSearch onSearch={handleSearch} activeTab={value} searchQuery={searchQuery} setSearchQuery={setSearchQuery}
                                setSearchType={setSearchType} searchType={searchType} />
                        </div>
                    </TabList>
                </Box>
                <Box>
                    <TabPanel value="1" sx={{ p: 0 }}>
                        <AllCustomerTabContent
                            setData={setAllCustomerData}
                            setSiteAvailable={setSiteAvailable}
                            setDisableSubmit={setDisableSubmit}
                            searchQuery={searchQuery}
                            searchType={searchType}
                        />
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 0 }}>
                        <FavoritesContent searchQuery={searchQuery} searchType={searchType} handleCancel={handleCancel}/>
                    </TabPanel>
                    <TabPanel value="3" sx={{ p: 0 }} >
                        < RecentsTab handleClose={handleClose} searchQuery={searchQuery}
                            searchType={searchType} />
                    </TabPanel>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            backgroundColor: "#F4F4F4",
                            padding: "8px 24px",
                        }}
                        className="popoverFooter"
                    >
                        {(allCustomerData?.selectedSites?.length == 0 ||
                            allCustomerData?.selectedAreas?.length == 0) &&
                            siteAvailable && tabActiveFrom !== "2" && tabActiveFrom !== "3" && (
                                <Stack sx={{ mr: "auto", alignSelf: "center !important" }}>
                                    <Alert
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            p: 0,
                                            fontSize: "12px",
                                            color: "#E34935",
                                        }}
                                        icon={<DangerIcon fontSize="inherit" />}
                                        severity="plain"
                                    >
                                        {((allCustomerData?.selectedSites?.length != 0 &&
                                            allCustomerData?.selectedAreas?.length == 0) ||
                                            (allCustomerData?.selectedSites?.length == 0 &&
                                                allCustomerData?.selectedAreas?.length == 0)) &&
                                            areaErrorMessage}
                                        {allCustomerData?.selectedAreas?.length != 0 &&
                                            allCustomerData?.selectedSites?.length == 0 &&
                                            siteErrorMessage}
                                    </Alert>
                                </Stack>
                            )}
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            sx={cancelBtnStyle}
                        >
                            {actions["cancel"]}
                        </Button>
                        {
                            tabActiveFrom == '1' && <Button variant="contained" color="primary" disabled={disableSubmit} onClick={handleSubmit} sx={submitBtnStyle}>
                                {actions["apply"]}
                            </Button>
                        }</Box>
                </Box >
            </TabContext >
        </Box >
    );
}

export default Tabs;