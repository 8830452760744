import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { consolidateByState, getTotalForAll } from "./MockApi";
import AlarmCard from "./AlarmCard";
import ElectricityCard from "./ElectricityCard";
import PrefectureAccordion from "./PrefectureAccordion";
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";
import TableActions from "../../common/customTable/TableActions";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";
import { landingPageStyle } from "./LandingPageStyle";
import { updateAccordionList } from "../../redux/slices/customerSlice";
import { electricityConsumptionURL, getHomeAllSiteList } from "../../services/apiConfig";
import useFetch from "../../common/hook/useFetch";
import NoPrefecture from "./NoPrefecture";
import usePost from '../../common/hook/usePost';
import { getSingleStateName } from "../../common/utils/CommonUtils";

function LandingPage() {
  const [data, setData] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState({});
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [siteListCount, setSiteListCount] = useState("blank");
  const { accordionList } = useSelector((state) => state.customerInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { recentSearchId, selectedCategory,isUpdateRecentSearchId } = useSelector(
    (state) => state?.customer
  );
  const {
    landingPage: { cards, siteListTableHeader, prefectureSort },
    sortBy,
  } = t("mainContainer");

  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost();

  const {
    data: fetchsitesData,
    error: fetchsiteError,
    loading: fetchsiteErrorLoading,
    fetchData: getSitesDataList,
  } = useFetch();


  useEffect(() => {
    if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
      if (fetchsitesData?.length === 0 || !fetchsitesData?.some(obj => obj?.hasOwnProperty('state'))) {
        setSiteListCount('empty')
      }
    }
  }, [fetchsiteErrorLoading, fetchsitesData, fetchsiteError]);


  useEffect(() => {
    if (recentSearchId) {
      const currentEpochTime = Math.floor(Date.now() / 1000);
      const secondsIn24Hours = 24 * 60 * 60;
      const endingDate = currentEpochTime;
      const startingDate = endingDate - secondsIn24Hours;
      const recentSearchPayload = {
        searchId: recentSearchId,
        startDate: startingDate,
        endDate: endingDate
      };
      doPostPrefectureApi(electricityConsumptionURL, recentSearchPayload);
      setSiteListCount("blank")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentSearchId,isUpdateRecentSearchId]);

  useEffect(() => {
    if (selectedCategory?.id) {
      getSitesDataList(getHomeAllSiteList(selectedCategory?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory?.id]);


  useEffect(() => {
    if (prefectureApiLoading && !prefectureApiError && !prefectureApiData) {
      setLoading(true);
    } else if (
      prefectureApiData && !prefectureApiLoading &&
      !prefectureApiError
    ) {
      setData(prefectureApiData?.result);
      dispatch(updateAccordionList(prefectureApiData?.result));
      const consolidated = consolidateByState(prefectureApiData?.result);
      setConsolidatedData(consolidated);
      const consolidatedKeys = Object.keys(consolidated);
      if (consolidatedKeys?.length === 1) {
        setExpandedAccordion(consolidatedKeys[0]);
      } else {
        setExpandedAccordion(null);
      }
      setLoading(false)
    }
  }, [prefectureApiData, prefectureApiError, prefectureApiLoading, dispatch]);

  useEffect(() => {
    setData(accordionList);
  }, [accordionList]);

  const handleAccordionChange = (state) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? state : null);
  };

  const handleActionSelection = (action) => {
    setSelectedAction(action);
    const sortedKeys = Object.keys(consolidatedData)?.sort((a, b) => {
      const aData = consolidatedData[a];
      const bData = consolidatedData[b];
      if (action === prefectureSort[0]) {
        return bData?.electricity - aData?.electricity;
      } else if (action === prefectureSort[1]) {
        return bData?.alarmCount - aData?.alarmCount;
      } else if (action === prefectureSort[2]) {
        return a.localeCompare(b);
      } else if (action === prefectureSort[3]) {
        return b.localeCompare(a);
      } else {
        return 0; // No sorting for other actions
      }
    });
    const sortedConsolidatedData = sortedKeys?.reduce((acc, key) => {
      acc[key] = consolidatedData[key];
      return acc;
    }, {});
    setConsolidatedData(sortedConsolidatedData);
  };

  const { totalElectricity, totalAlarms } = getTotalForAll(data);
  return (
    <>
      {!prefectureApiData && !prefectureApiError && prefectureApiLoading ? (
        <CustomCircularProgress
          size={44}
          inputSx={landingPageStyle?.loaderStyle}
        />
      ) : (
        <Box className="landingPageParentBox">
          {!!data?.length ? (
            <>
              <Box className="outerCardContent">
                <ElectricityCard
                  totalElectricity={totalElectricity}
                  cards={cards}
                />
                <AlarmCard cards={cards} totalAlarms={totalAlarms} />
              </Box>
              {!expandedAccordion && (
                <div
                  style={{
                    marginLeft: "14px",
                    marginRight: "14px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {selectedAction && (
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "12px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      {sortBy}: {selectedAction}
                    </Typography>
                  )}
                  <TableActions
                    options={prefectureSort}
                    setSelectedAction={setSelectedAction}
                    handleActionSelection={handleActionSelection}
                    actionIcon={<FilterIcon />}
                  />
                </div>
              )}
              {Object.keys(consolidatedData)?.map((state) => {
                const stateData = data?.filter((site) => site?.state === state);
                return (
                  <PrefectureAccordion
                    key={state}
                    stateName={getSingleStateName(state)}
                    cards={cards}
                    title={`Sites in ${getSingleStateName(state)}`}
                    data={stateData}
                    columns={siteListTableHeader}
                    expanded={expandedAccordion === state}
                    onChange={handleAccordionChange(state)}
                    selectedCategory={selectedCategory}
                  />
                );
              })}
            </>
          ) : (
            siteListCount === 'empty' && (
              <NoPrefecture selectedCategory={selectedCategory} />
            )
          )}
        </Box>
      )}
    </>
  );
}

export default LandingPage;

