import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
	Box,
	InputLabel,
	Grid,
	SvgIcon,
	useMediaQuery,
	useTheme,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next'; 
import { useSelector } from 'react-redux'; 
import { ReactComponent as LocationIcon } from '../../assets/images/LocationIcon.svg';
import { ReactComponent as LocationIconGray } from '../../assets/images/LocationIconDisable.svg';
import CustomSelect from '../../common/reusable-comps/select/CustomSelect';
import CustomTextField from '../../common/reusable-comps/text-field/CustomTextField';
import './setting.scss';
import { prefectures } from './CustomerData';
import { isValidJapanesePostalCode } from '../../common/utils/CommonUtils';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CustomButton from '../../common/buttons/CustomButton';
import { isEmpty } from '../../common/utils/CommonUtils';
import CustomModal from '../../common/commonPopup/CustomModal';
import { ReactComponent as JapanImg } from '../../assets/images/Japan.svg';
import useFetch from '../../common/hook/useFetch';
import { siteEndPoint,getAllSiteImagesURl } from '../../services/apiConfig'; 
import GMap from './CustomGoogleMap'; 


const SiteAddress = ({
	formData,
	siteAddress,
	setFormData, 
	setFormError,
	formTouched,
	isSiteEdit,
}) => {
	const [openModal, setOpenModal] = useState(false); 
	const [addresses, setAddresses] = useState([]);
	const [isvalidPostalLength, setValidPostalLength] = useState(false);  

	const [locations,setLocations]=useState([])

	const { selectedCustomer, editSiteId, siteFormData } = useSelector(
		state => state.customerInfo ?? {}
	);

	const displayName = formData?.siteDetail?.displayName;
	const desc = formData?.siteDetail?.description;

	const {
		data: sitesData,
		error: sitesError,
		loading: sitesLoading,
		fetchData: fetchSites,
	} = useFetch();


	const {
		data: allSiteImages,
		error: allSitesImagesError,
		loading: allSitesImagesLoading,
		fetchData: fetchAllSitesImages,
	} = useFetch();


	useEffect(() => {
		const payload = {
			limit: '100',
			page: 1,
			customerId: selectedCustomer,
		};

		if (selectedCustomer&&!sitesData) {
			fetchSites(siteEndPoint, payload);
			fetchAllSitesImages(getAllSiteImagesURl(selectedCustomer))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCustomer]);

	
	useEffect(() => {
		if (
			sitesData&& 
			!sitesError &&
			!sitesLoading && allSiteImages && !allSitesImagesError && !allSitesImagesLoading
		) {

			  // Create a map of image URLs by site ID
			  const imageUrlMap = allSiteImages?.reduce((map, image) => {
				if (image.imageUrl) {
				  map[image.id] = image.imageUrl;
				}
				return map;
			  }, {});

			  
			const addresses = sitesData.map(site => {
				const prefecture= prefectures?.find(pref => pref?.value === site.state)?.label
				return {
				key:site.id,
				address: `${site.address1}, ${site.city}, ${prefecture}`,
				postalCode: site.postalCode,
				country: 'Japan',
				siteName: site.displayName,
				description: site.description, 
				imageUrl: imageUrlMap[site.id] // Add the image URL based on site.id
			};
		});
			setAddresses(addresses);
			
		}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sitesData, sitesError, sitesLoading]);

	useEffect(() => {
		if (
			sitesData&&  !sitesError && !sitesLoading && allSiteImages && !allSitesImagesError && !allSitesImagesLoading
		) {

			 // Create a map of image URLs by site ID
			 const imageUrlMap = allSiteImages?.reduce((map, image) => {
				if (image.imageUrl) {
				  map[image.id] = image.imageUrl;
				}
				return map;
			  }, {});

			//Aleardy Sites Available in the customer
			const addresses = sitesData.map(site => {
				
				if (site?.id === editSiteId) {
					const prefecture = prefectures?.find(
						pref => pref?.value === siteAddress?.state
					)?.label;
					return {
						key:site.id,
						address: `${siteAddress?.address1}, ${siteAddress?.city}, ${prefecture}`,
						postalCode:  siteAddress?.postalCode,
						country: 'Japan',
						siteName: site.displayName,
						description: site.description, 
						imageUrl: imageUrlMap[site.id] // Add the image URL based on site.id
					};
				} else {
					const prefecture = prefectures?.find(pref => pref?.value === site?.state)?.label
					return {
						key:site.id,
						address: `${site?.address1}, ${site?.city}, ${prefecture}`,
						postalCode:  site?.postalCode,
						country: 'Japan',
						siteName: site.displayName,
						description: site.description, 
						imageUrl: imageUrlMap[site.id] // Add the image URL based on site.id
					};
				}
			});

			// Add New Site if editSiteId doesn't exist
			if (!isSiteEdit && siteAddress?.postalCode !== '') {
				const prefecture = prefectures?.find(
					pref => pref?.value === siteAddress?.state
				)?.label||'';
				const formSiteInfo = {
					key:addresses?.length+1,
					address: `${siteAddress?.address1}, ${siteAddress?.city}, ${prefecture}`,
					postalCode:  siteAddress?.postalCode,
					country: 'Japan',
					siteName: displayName,
					description: desc,  
				};

				addresses.push(formSiteInfo);
			}

			// setAddresses(addresses);
			setLocations(addresses)
		} else { 
			// Add New Site
			const prefecture = prefectures?.find(
				pref => pref?.value === siteAddress?.state
			)?.label;
			const formSiteInfo = {
				key:addresses?.length+1,
				address: `${siteAddress?.address1}, ${siteAddress?.city}, ${prefecture}`,
				postalCode:  siteAddress?.postalCode,
				country: 'Japan',
				siteName: displayName,
				description: desc,   
			};
			// setAddresses([formSiteInfo]);
			setLocations([formSiteInfo])
		}

		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteAddress, sitesData]);
 

 
	const handleOpen = () => {
		if (siteAddress?.postalCode?.length <= 7) {
			setValidPostalLength(true);
		} else {
			setOpenModal(true);
		}
	};

	const handleClose = () => {
		setOpenModal(false);   
	};

	const handleChange = e => {
		const { name, value } = e?.target || e;

		if (value?.length == 8) {
			setValidPostalLength(false);
		} else {
			setValidPostalLength(false);
		}

		setFormData(prevData => ({
			...prevData,
			siteAddress: { ...prevData?.siteAddress, [name]: value },
		}));
		setFormError(prevErrors => ({
			...prevErrors,
			siteAddress: {
				...prevErrors?.siteAddress,
				[name]: '',
			},
		}));
	};

	const getValidation = (fieldName, value, formTouched) => {
		if (fieldName === 'state' && formTouched) {
			if (isEmpty(value)) {
				return prefecturesText.error;
			}
		} else if (fieldName === 'city' && formTouched) {
			if (isEmpty(value)) {
				return city.error;
			}
		} else if (fieldName === 'postalCode' && formTouched) {
			if (isEmpty(value)) {
				return postalCode.error1;
			} else if (!isValidJapanesePostalCode(value)) {
				return postalCode.error2; // Error message for invalid Japanese postal code
			} else if (isvalidPostalLength) {
				return postalCode.error2; // Error message for invalid Japanese postal code
			}
		} else if (fieldName === 'address1' && formTouched) {
			if (isEmpty(value)) {
				return buildingName.error;
			}
		}
		return '';
	};

 
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { t } = useTranslation();
	const { country, postalCode, prefecturesText, city, buildingName, map } = t('mainContainer').settingsPageContent.siteManagementContent.customerDetails.siteAddress;
	const isValidAddress = !(
		siteAddress?.postalCode &&
		siteAddress?.state &&
		siteAddress?.city &&
		siteAddress?.address1
	)

const { addCustomerForm  } =  t('mainContainer').settingsPageContent?.siteManagementContent;
const {  prefecturesField } = addCustomerForm;
function getPrefecturesByCountry(countryValue) { 
    return prefecturesField?.options?.filter(
      (prefecture) => prefecture?.country === countryValue
    );
  }
	return (
		<Grid justifyContent="flex-start" alignItems="flex-start">
			<Box className="siteAddressBox">
				<div style={{ textAlign: 'left' }}>
					<InputLabel
						className="globalInputLabelStyle"
						sx={{ marginTop: 2, marginBottom: 1.5 }}
					>
						{country.label}
					</InputLabel>
					<Box
						display="flex"
						alignItems="left"
						sx={{ marginTop: 2, marginBottom: 1.5 }}
					>
						<SvgIcon
							className="country-indicater"
							sx={{
								marginRight: '0',
								width: '35px',
								height: '35px',
								boxShadow: 'none',
								mt: '-6px',
							}}
						>
							<JapanImg />
						</SvgIcon>
						<InputLabel
							htmlFor="input-with-icon-adornment"
							sx={{ color: '#161616', fontSize: '14px', fontWeight: '400' }}
						>
							{country?.options[0]?.label}
						</InputLabel>
					</Box>
				</div>

				<CustomTextField
					id="postalCode"
					name="postalCode"
					label={postalCode.label}
					placeholder={postalCode.placeholder}
					handleChange={handleChange}
					value={siteAddress?.postalCode}
					width="100%"
					error={getValidation(
						'postalCode',
						siteAddress?.postalCode,
						isvalidPostalLength || formTouched
					)}
					helperText={getValidation(
						'postalCode',
						siteAddress?.postalCode,
						isvalidPostalLength || formTouched
					)}
				/>

				<CustomSelect
					id="state"
					name="state"
					label={prefecturesText?.label}
					onChange={handleChange}
					value={siteAddress?.state}
					options={getPrefecturesByCountry(country?.options[0]?.value)}
					width="100%"
					displayEmpty={true}
					inputComponent={UnfoldMoreIcon}
					noMarginIcon={true}
					error={getValidation('state', siteAddress?.state, formTouched)}
					helperText={getValidation('state', siteAddress?.state, formTouched)}
				/>

				<CustomTextField
					id="city"
					name="city"
					label={city.label}
					placeholder={city.placeholder}
					handleChange={handleChange}
					value={siteAddress?.city}
					width="100%"
					maxLength={50}
					error={getValidation('city', siteAddress?.city, formTouched)}
					helperText={getValidation('city', siteAddress?.city, formTouched)}
				/>

				<CustomTextField
					id="address1"
					name="address1"
					label={buildingName.label}
					placeholder={buildingName.placeholder}
					handleChange={handleChange}
					value={siteAddress?.address1}
					width="100%"
					error={getValidation('address1', siteAddress?.address1, formTouched)}
					helperText={getValidation(
						'address1',
						siteAddress?.address1,
						formTouched
					)}
				/>

				<InputLabel
					className="globalInputLabelStyle"
					sx={{ marginTop: 2, marginBottom: 1.5 }}
				>
					{map?.label}
				</InputLabel>
				<CustomButton
					className="viewMap-btn"
					variant="outlined"
					onClick={handleOpen}
					disabled={isValidAddress }
					icon={isValidAddress?<LocationIconGray/>:<LocationIcon />}
					width="158px"
					marginBottom="5px"
					iconPosition="end"
				>
					{map?.placeholder}
				</CustomButton>
			</Box>

			<CustomModal
				title={siteFormData?.name || displayName}
				open={openModal}
				onClose={handleClose}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth="xl"
				contentPadding={true}
			>
			{!sitesData&&sitesError&&!sitesLoading&&<div>Something went wrong</div>}
			{!sitesData&&!sitesError&&sitesLoading&&<div>...Loading Map</div>}
			{sitesData&&!sitesError&&!sitesLoading	&&<GMap locat={locations}/>}
			</CustomModal>
		</Grid>
	);
};

export default SiteAddress;
